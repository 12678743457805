import { info, err } from 'common/logger'
import { baseurl } from 'common/config'

const runFetch = async (url) => {
  try {
    const res = await fetch(url)
    return res.json()
  } catch (error) {
    err(JSON.stringify(error))
    throw error
  }
}

export const getSimulations = async () => {
  const url = new URL('/simulation/simulations.json', baseurl)
  info(`Fetching data from ${url}`)
  return await runFetch(url)
}

export const getTests = async () => {
  const url = new URL('/labtest/labtest.json', baseurl)
  info(`Fetching data from ${url}`)
  return await runFetch(url)
}

export const getSimulation = async (simulationId) => {
  const url = new URL(`/simulation/${simulationId ?? 'lunar'}/${simulationId ?? 'lunar'}.json`, baseurl)
  console.log(url)
  info(`Fetching data from ${url}`)
  return await runFetch(url)
}

export const getSuits = async () => {
  const url = new URL('/suit/manifest.json', baseurl)
  info(`Fetching data from ${url}`)
  return await runFetch(url)
}
