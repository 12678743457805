
import React, { useState, useRef,useEffect } from 'react';
import { useLocation } from 'react-router-dom';


import FrontNavbar from '../FrontNavbar';
import 'assets/css/simulate.css';
import classNames from "classnames";
import { Link } from 'react-router-dom';
import { Canvas, useFrame, extend, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { Html } from '@react-three/drei';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Footer from 'front/Footer/Footer';
// reactstrap components
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Input,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    Modal,
    NavbarToggler,
    ModalHeader,
    Row,
    Card,
    CardHeader,
    CardBody,
    Col,
    CardTitle,
    Table
} from "reactstrap";
import Header from 'front/Header/Header';
import { API_ASSET_URL } from './common/config';

function SimulateAnalytics(props) {
    const [isWebcamOn, setWebcamOn] = useState(false);
    const location = useLocation();

    extend({ OrbitControls });
    function AstroStaticModel() {
        const astroStaticModelRef = useRef();
        const astroStaticControlsRef = useRef();
        // Use useFrame directly inside the functional component body
        useFrame(() => {
          // Rotate the model continuously
          // modelRef.current.rotation.y += 0.01;
        });
      
      const { scene: scene2 } = useGLTF(`${API_ASSET_URL}/assets/models/Metakosmos.glb`);
      
        useEffect(() => {
          if (astroStaticModelRef.current) {
            astroStaticModelRef.current.position.y = -3.5; // Move down by 2 units
            astroStaticModelRef.current.scale.set(25, 25, 25); // Scale by a factor of 2 in all dimensions
    
             // Create a white material
        //   const whiteMaterial = new THREE.MeshBasicMaterial({ color: 0x606060 });
    
          // Apply the white material to all the meshes in the model
        //   scene2.traverse((child) => {
        //     if (child.isMesh) {
        //       child.material = whiteMaterial;
        //     }
        //   });
            
          }
        }, [scene2]);
        return (
          <>
            <primitive object={scene2} ref={astroStaticModelRef} />
            <orbitControls ref={astroStaticControlsRef} args={[useThree().camera, useThree().gl.domElement]} enableZoom={true} />
          </>
        );
      }
    return (
        <div>
            <Header currentRoute={location.pathname}></Header>
            <Container fluid style={{background:'#191f24'}}>
                <div className="content">
                    <Row className='simulation'>
                        <Col className='mt-2' md="2" style={{}}>
                            <Card className='rounded mt-5 content-card' style={{ border: '2px #043c5e solid' }}>
                                <CardBody style={{ background: '#14181B' }}>
                                    <Row className=''>
                                        <Col className='mt-2' md="12" style={{ borderBottom: '2px #043c5e solid' }}>
                                            <p className=''>Select a Test</p>
                                        </Col>

                                        <Col className='mt-3' md="12">
                                            <h6>RANGE OF MOTION</h6>
                                        </Col>
                                        <Col md="12">
                                            <p>Visor Reach</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Cross Shoulder Elbow Touch</p>
                                        </Col>
                                        <Col className='mt-2' md="12" style={{ borderBottom: '2px #043c5e solid' }}>
                                            <p>Recovery from Supine</p>
                                        </Col>
                                        <Col className='mt-3' md="12">
                                            <h6>SPACE STATION TASKS</h6>
                                        </Col>
                                        <Col md="12">
                                            <p>Remove Tools from Suit</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Suit Dust Removal</p>
                                        </Col>
                                        <Col className='mt-2' md="12" style={{ borderBottom: '2px #043c5e solid' }}>
                                            <p>Self Don/Doff Helmet-Gloves</p>
                                        </Col>
                                        <Col className='mt-3' md="12">
                                            <h6>SURFACE OPS</h6>
                                        </Col>
                                        <Col md="12">
                                            <p>Descend a Ladder</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Push/Pull a Cart</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Carry a Sample</p>
                                        </Col>
                                        {/* duplicate  */}

                                        <Col className='mt-2' md="12" style={{ borderBottom: '2px #043c5e solid' }}>
                                            <p>Self Don/Doff Helmet-Gloves</p>
                                        </Col>
                                        {/* <Col className='mt-3' md="12">
                                            <h6>SURFACE OPS</h6>
                                        </Col>
                                        <Col md="12">
                                            <p>Descend a Ladder</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Push/Pull a Cart</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Carry a Sample</p>
                                        </Col>
                                        <Col className='mt-2' md="12" style={{ borderBottom: '2px #043c5e solid' }}>
                                            <p>Self Don/Doff Helmet-Gloves</p>
                                        </Col> */}
                                        {/* <Col className='mt-3' md="12">
                                            <h6>SURFACE OPS</h6>
                                        </Col>
                                        <Col md="12">
                                            <p>Descend a Ladder</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Push/Pull a Cart</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Carry a Sample</p>
                                        </Col> */}

                                        <Col md="12">
                                            <Button className='simulation-button'>Add Task</Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="10">
                            <Container fluid>
                                <Row className=''>
                                    <Col className='mt-2' md="4">
                                        <Card className='mt-5'>
                                            <CardBody style={{ background: '#14181B' }}>
                                                <h6 className='text-center'>CAMERA VIEW</h6>
                                                {/* <img src={Picture3}  alt="Placeholder Image" /> */}
                                                <Canvas className='model'>
                                                <ambientLight />
                                                <AstroStaticModel />
                                                </Canvas>
                                            </CardBody>
                                        </Card>
                                        <Row className='text-center'>
                                        <Col md="12" lg="12">
                                         <Button className='simulation-button'>
                                            ANALYTICS
                                         </Button>
                                       </Col>
                                        </Row>
                                    </Col>
                                    <Col className='mt-2' md="8">
                                        <Card className='mt-5'>

                                            <CardBody style={{ background: '#14181B' }}>
                                                <h5 className='text-center session-summary'>SESSION SUMMARY</h5>
                                                <Table className='simulate-table'>
                                                    <thead>
                                                        <tr>
                                                            <th>Parameter</th>
                                                            <th>Value</th>
                                                            <th>Units</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Dynamic Postural Stability Index (PSI)</td>
                                                            <td>0.95</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Variability of Ground Reaction Forces (GRF)</td>
                                                            <td>CV/SD</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Limits of Stability</td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Center of Pressure (COP) length & excursion</td>
                                                            <td></td>
                                                            <td>Cms</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Spatiotemportal</td>
                                                            <td>ms-1</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Speed</td>
                                                            <td>Hz</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Stride Rate</td>
                                                            <td>E</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Stride Length</td>
                                                            <td>s/%</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Duration of Stance, Loading, propulsion</td>
                                                            <td>3</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Step Width</td>
                                                            <td>N</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Peak vertical & anterior/posterior GRF</td>
                                                            <td>ΖΖΖΖ</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Peak Medio-lateral GRF</td>
                                                            <td>N</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Minimum vertical GRF</td>
                                                            <td>N</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Loading & Propulsion rates</td>
                                                            <td>N/S</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Joint Movements</td>
                                                            <td>N/m</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Peak Normal Force (PNF)</td>
                                                            <td>z z z</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Minimal normal force (MNF)</td>
                                                            <td>N</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>PNF at rearfoot, midfoot and forefoot</td>
                                                            <td>N</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Measured Torque</td>
                                                            <td>Nm</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Force applied by exosuit layers</td>
                                                            <td>N</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Peak Joint Angles (max & min)</td>
                                                            <td>Degrees</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Range of Motion</td>
                                                            <td>Degrees</td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </CardBody>
                                        </Card>
                                        <Row className='text-center'>
                                            <Col md="12" lg="12"><Button className='simulation-button'>SAVE SESSION</Button></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </div>
                <Footer></Footer>
            </Container>
        </div>
    );
}

export default SimulateAnalytics;