import { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Canvas, useFrame, extend, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { Html } from '@react-three/drei';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Header from 'front/Header/Header';
import Footer from 'front/Footer/Footer';
import Medical from './Medical';
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Input,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    Modal,
    NavbarToggler,
    ModalHeader,
    Row,
    Card,
    CardHeader,
    CardBody,
    Col,
    CardTitle,
    Table
} from "reactstrap";
import { API_ASSET_URL } from 'front/Simulation/common/config';

const cardStyle = {
  background: `url(${API_ASSET_URL}/assets/images/eeg-visualization.gif)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '600px', // Adjusted height
        minHeight: '600px', // Set a minimum height for the card
        position: 'relative',
  // Other styles for the card if necessary
};


export default function Cognitive() {
  const location = useLocation();
    const planets = ['Mercury', 'Venus', 'Earth', 'Mars', 'Jupiter', 'Saturn', 'Uranus', 'Neptune'];

    const [selectedPlanetIndex, setSelectedPlanetIndex] = useState(2);

    const handlePlanetClick = (index) => {
      setSelectedPlanetIndex(index);
    };

    extend({ OrbitControls });
    function AstroStaticModel() {
        const astroStaticModelRef = useRef();
        const brainControlsRef = useRef();
        const [colorIndex, setColorIndex] = useState(0);
      
        // List of colors to cycle through
        const colors = [0xff0000, 0x00ff00, 0x0000ff, 0xffff00, 0xff00ff];
      
        useFrame(() => {
          astroStaticModelRef.current.rotation.y += 0.01;
        });
      
        const { scene: scene2 } = useGLTF(`${API_ASSET_URL}/assets/models/brain.glb`);
      
        // useEffect(() => {
        //   if (astroStaticModelRef.current) {
        //     astroStaticModelRef.current.position.y = -1.5;
        //     astroStaticModelRef.current.scale.set(3, 3, 3);
      
        //     // Set material color
        //     const whiteMaterial = new THREE.MeshBasicMaterial({ color: 0x808080 });
      
        //     scene2.traverse((child) => {
        //       if (child.isMesh) {
        //         child.material = whiteMaterial;
        //       }
        //     });
        //   }
        // }, [scene2]);
      
        // Change color every 2 seconds

        useEffect(() => {
          if (astroStaticModelRef.current) {
            astroStaticModelRef.current.position.y = -1.5;
            astroStaticModelRef.current.scale.set(3, 3, 3);
        
            // Create a canvas for the gradient texture
            const canvas = document.createElement('canvas');
            canvas.width = 256; // Texture width
            canvas.height = 256; // Texture height
        
            const context = canvas.getContext('2d');
        
            // Create a gradient that alternates between red and gray
            const gradient = context.createLinearGradient(0, 0, 0, canvas.height);
            gradient.addColorStop(0, '#ff0000'); // Start with red
            gradient.addColorStop(0.2, '#00ff00'); // Transition to green at 50%
            gradient.addColorStop(0.7, '#ff0000'); // End with red
        
            // Fill the canvas with the gradient
            context.fillStyle = gradient;
            context.fillRect(0, 0, canvas.width, canvas.height);
        
            // Create a texture from the canvas
            const alternatingTexture = new THREE.CanvasTexture(canvas);
        
            // Apply the gradient texture to a material
            const gradientMaterial = new THREE.MeshBasicMaterial({ map: alternatingTexture });
        
            // Traverse the scene and apply the material to meshes (e.g., human brain model)
            scene2.traverse((child) => {
              if (child.isMesh) {
                child.material = gradientMaterial;
              }
            });
          }
        }, [scene2]);
        
        useEffect(() => {
          const interval = setInterval(() => {
            setColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
          }, 2000);
      
          return () => clearInterval(interval);
        }, []);
      
        return (
          <>
            <primitive object={scene2} ref={astroStaticModelRef} />
            <orbitControls ref={brainControlsRef} args={[useThree().camera, useThree().gl.domElement]} enableZoom={true} />
          </>
        );
      }

  return (
    <div>
    <Header currentRoute={location.pathname}></Header>
    <Container fluid>
       <Row>
       <Col md="12" className='m-2'>
                       <Row className='mr-3 mt-3'>
                           <Col className='' md="4">
                               <Card className='main-dashboard' style={{ background: '#14181B' }}>
                                   <div className="inner_dashboard">
                                       <span className="corner-top-left"></span>
                                       <span className="corner-top-right"></span>
                                       <CardBody>
                                       <Canvas className='model'>
                                                <ambientLight />
                                                <AstroStaticModel />
                                                </Canvas>
                                       </CardBody>
                                       <span className="corner-bottom-left"></span>
                                       <span className="corner-bottom-right"></span>
                                   </div>
                               </Card>
                           </Col>
                           <Col className='' md="8">
                               <Card className='main-dashboard' style={cardStyle}>
                                   <div className="inner_dashboard">
                                       <span className="corner-top-left"></span>
                                       <span className="corner-top-right"></span>
                                       <CardBody   >
                                       {/* <Medical /> */}
                                       {/* <img src={EegVisualization} alt='EEG-VISULIZATION' style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
                                       </CardBody>
                                       <span className="corner-bottom-left" style={{ bottom:'-102%' }}></span>
                                       <span className="corner-bottom-right" style={{ bottom:'-102%' }}></span>
                                   </div>
                               </Card>
                           </Col>
                       </Row>
                   </Col>
       </Row>
    </Container>
    <Footer></Footer>
   </div>
  )
}
