import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import "./LineChart.css"; // Import CSS

const LineChart = ({isModalOpen, chartData }) => {
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  

  const option = {
    title: {
      // text: `${chartData?.metric ? chartData.metric : 'CO₂ Level' }`,
      left: "center",
      textStyle: {
        fontSize: 11,
        fontFamily: "Alternox-Light",
        color: "#fff", // Neon blue title
      },
    },
    tooltip: {
      trigger: "axis",
      backgroundColor: "#1e2429", // Dark background
      borderColor: "#00E5FF", // Neon blue border
      textStyle: {
        color: "#FFF", // White text
      },
    },
    xAxis: {
      type: "category",
      data: labels,
      axisLine: {
        lineStyle: {
          color: "#FFF", // White axis line
        },
      },
      axisLabel: {
        color: "#AAA", // Light gray labels
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#FFF", // White axis line
        },
      },
      splitLine: {
        lineStyle: {
          color: "#555", // Subtle grid lines
          type: "dashed", // Dashed grid lines
        },
      },
      axisLabel: {
        color: "#AAA", // Light gray labels
      },
    },
    series: [
      {
        name: "Data",
        type: "line",
        data: data,
        smooth: true, // Smooth curves
        lineStyle: {
          color: "#00E5FF", // Neon blue line
          width: 2, // Line width
        },
        itemStyle: {
          color: "", // Neon blue points
        },
      },
    ],
    backgroundColor: "", // Dark background
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const newData = Math.random() * 100;
      const time = new Date().toLocaleTimeString();

      setData((prev) => [...prev.slice(-9), newData]);
      setLabels((prev) => [...prev.slice(-9), time]);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const chartHeight = isModalOpen ? "500px" : "250px";


  return (
    <div >
      <ReactECharts option={option} style={{ height: chartHeight, width: "100%" }}  />
    </div>
  );
};

export default LineChart;
