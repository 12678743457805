import React, { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";

const DynamicAreaChart = ({isModalOpen, chartData}) => {
  const [data, setData] = useState([120, 132, 101, 134, 90]);

  useEffect(() => {
    const interval = setInterval(() => {
      setData((prev) => {
        const newData = [...prev, Math.floor(Math.random() * 200)];
        if (newData.length > 10) newData.shift();
        return newData;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const options = {
    title: {
      // text: `${chartData?.metric ? chartData.metric : 'Noise Reduction' }`,
      left: "center",
      textStyle: {
        fontSize: 12,
        fontWeight: "",
        color: "#FFF",
        fontFamily: "Alternox-Light" // Neon blue title
      },
    },
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: data.map((_, index) => `Point ${index + 1}`),
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "Data",
        type: "line",
        areaStyle: {},
        data: data,
      },
    ],
  };

  const chartHeight = isModalOpen ? "500px" : "250px";

  return <ReactECharts option={options} style={{ height: chartHeight, width: "100%" }}/>;
};

export default DynamicAreaChart;
