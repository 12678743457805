import { useEffect } from 'react'
import { useStopwatch } from 'react-timer-hook'
import { useSimulation } from '../SimulationContext'
import './DurationClock.scss'

export default function DurationClock({ isFullscreen }) {
  const { seconds, minutes, hours, start, reset, isRunning } = useStopwatch({ autoStart: false })
  const { isTasksCompleted, sessionDuration, setSessionDuration, selectedSession } = useSimulation()

  useEffect(() => {
    const isSessionInprogress = selectedSession.subtasks?.findIndex((val) => val?.status === 'ongoing') !== -1
    if (!isTasksCompleted && isSessionInprogress && selectedSession && !isRunning) {
      start()
    } else if (isTasksCompleted && isSessionInprogress && isRunning) {
      setSessionDuration({ hours: hours, minutes: minutes, seconds: seconds })
      reset()
    }
  }, [
    isTasksCompleted,
    sessionDuration,
    setSessionDuration,
    selectedSession,
    hours,
    minutes,
    seconds,
    start,
    isRunning,
    reset,
  ])

  if (isFullscreen) {
    return (
      <div className="duration-clock-container">
        <label className="value">
          {hours < 10 ? `0${hours}` : hours}:{minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </label>
        <label className="label">duration</label>
      </div>
    )
  } else {
    return null
  }
}
