import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";

const ThemeRiverChart = ({isModalOpen, chartData}) => {
    const chartRef = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);

    useEffect(() => {
        const chart = echarts.init(chartRef.current);
        setChartInstance(chart);

        const option = {
            title: {
                // text: `${chartData?.metric ? chartData.metric : 'Microphone Sensitivity' }`,
                left: "center",
                textStyle: {
                    fontSize: 12,
                    fontFamily: "Alternox-Light",
                    color: "#FFF", // Neon blue title
                  },
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "line",
                },
            },
            legend: {
                top: "bottom",
                data: ["Category A", "Category B", "Category C"],
            },
            singleAxis: {
                type: "time",
                left: 50,
                right: 50,
                top: 50,
                bottom: 50,
                axisLabel: {
                    interval: 0,
                },
            },
            series: [
                {
                    type: "themeRiver",
                    data: [
                        ["2024-12-01T00:00", 10, "Category A"],
                        ["2024-12-01T00:00", 15, "Category B"],
                        ["2024-12-01T00:00", 5, "Category C"],
                    ],
                },
            ],
        };

        chart.setOption(option);

        return () => {
            chart.dispose();
        };
    }, [chartData]);

    useEffect(() => {
        if (!chartInstance) return;

        // Simulate real-time data updates
        const interval = setInterval(() => {
            const now = new Date();
            const newTime = now.toISOString();
            const newData = [
                [newTime, Math.random() * 20 + 10, "Category A"],
                [newTime, Math.random() * 15 + 5, "Category B"],
                [newTime, Math.random() * 10 + 5, "Category C"],
            ];

            const option = chartInstance.getOption();
            option.series[0].data = [...option.series[0].data, ...newData].slice(
                -50 // Keep the last 50 data points
            );

            chartInstance.setOption(option);
        }, 1000);

        return () => clearInterval(interval);
    }, [chartInstance]);

    const chartHeight = isModalOpen ? "500px" : "250px";


    return <div ref={chartRef} style={{ width: "100%", height: chartHeight }} />;
};

export default ThemeRiverChart;
