export const EMOTIBIT_BIOMETRIC_TYPETAG = [
  {
    label: 'EDA - Electrodermal Activity',
    value: 'EA',
  },
  {
    label: 'EDL - Electrodermal Level',
    value: 'EL',
  },
  {
    label: 'EDR - Electrodermal Response (EmotiBit V4+ combine ER into EA signal)',
    value: 'ER',
  },
  {
    label: 'PPG Infrared',
    value: 'PI',
  },
  {
    label: 'PPG Red',
    value: 'PR',
  },
  {
    label: 'Temperature',
    value: 'TO',
  },
  {
    label: 'Temperature via Medical -grade Thermopile (only on EmotiBit MD)',
    value: 'TH',
  },
  {
    label: 'Accelerometer X',
    value: 'AX',
  },
  {
    label: 'Accelerometer Y',
    value: 'AY',
  },
  {
    label: 'Accelerometer Z',
    value: 'AZ',
  },
  {
    label: 'Gyroscope X',
    value: 'GX',
  },
  {
    label: 'Gyroscope y',
    value: 'GY',
  },
  {
    label: 'Gyroscope Z',
    value: 'GZ',
  },
  {
    label: 'Magnetometer X',
    value: 'MX',
  },
  {
    label: 'Magnetometer Y',
    value: 'MY',
  },
  {
    label: 'Magnetometer Z',
    value: 'MZ',
  },
  {
    label: 'Skin Conductance Response (SCR) Ampitude',
    value: 'SA',
  },
  {
    label: 'Skin Conductance Response (SCR) Rise Time',
    value: 'SR',
  },
  {
    label: 'Skin Conductance Response (SCR) Frequency',
    value: 'SF',
  },
  {
    label: 'Heart Rate',
    value: 'HR',
  },
  {
    label: 'Heart Inter-beat Interval',
    value: 'BI',
  },
  {
    label: 'Humidity (Only on EmotiBit Alpha/Beta V1,V2,V3)',
    value: 'HO',
  },
]

export const EMOTIBIT_GENERAL_NETWORKING_TYPETAG = [
  {
    label: 'EmotiBit Info Json',
    value: 'EI',
  },
  {
    label: 'Data Clipping, TypeTag in Paylload',
    value: 'DC',
  },
  {
    label: 'Data Overflow, TypeTag in payload',
    value: 'DO',
  },
  {
    label: 'Battery Percentage Remaining',
    value: 'B%',
  },
  {
    label: 'Battery Voltage',
    value: 'BV',
  },
  {
    label: 'SD card percent capacity filled',
    value: 'D%',
  },
  {
    label: 'Request Data, TypeTag in Payload',
    value: 'RD',
  },
  {
    label: 'Ping',
    value: 'PI',
  },
  {
    label: 'Pong',
    value: 'PO',
  },
  {
    label: 'Reset',
    value: 'RS',
  },
]

