import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import "echarts-gl";
import "./Bar3DChart.css"; // Import CSS for styling

const Bar3DChart = ({isModalOpen, chartData}) => {
  const [data, setData] = useState(
    Array.from({ length: 10 }, (_, x) =>
      Array.from({ length: 10 }, (_, y) => [x, y, Math.random() * 100])
    ).flat()
  );

  const option = {
    title: {
      // text: `${chartData?.metric ? chartData.metric : 'Energy Consumption' }`,
      left: "center",
      textStyle: {
        fontSize: 12,
         fontFamily: "Alternox-Light",
        fontWeight: "",
        color: "#FFF", // Neon blue title
      },
    },
    tooltip: {
      trigger: "item",
      backgroundColor: "#333",
      borderColor: "#00E5FF",
      textStyle: {
        color: "#FFF",
      },
    },
    xAxis3D: {
      type: "category",
      name: "X-Axis",
      nameTextStyle: { color: "#AAA" },
      axisLine: { lineStyle: { color: "#FFF" } },
    },
    yAxis3D: {
      type: "category",
      name: "Y-Axis",
      nameTextStyle: { color: "#AAA" },
      axisLine: { lineStyle: { color: "#FFF" } },
    },
    zAxis3D: {
      type: "value",
      name: "Value",
      nameTextStyle: { color: "#AAA" },
      axisLine: { lineStyle: { color: "#FFF" } },
    },
    grid3D: {
      viewControl: {
        projection: "perspective", // Use perspective for depth
        autoRotate: true, // Enable auto-rotation
        autoRotateSpeed: 30,
      },
      boxHeight: 100,
      boxWidth: 150,
      boxDepth: 150,
      axisPointer: { show: true },
    },
    series: [
      {
        type: "bar3D",
        data: data,
        shading: "lambert", // Smooth shading for a professional look
        itemStyle: {
          color: (params) => {
            const value = params.value[2];
            return `#BAD808`; // Dynamic color gradient
          },
        },
        label: {
          show: false,
        },
      },
    ],
    backgroundColor: "", // Dark background for modern design
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setData(
        Array.from({ length: 10 }, (_, x) =>
          Array.from({ length: 10 }, (_, y) => [x, y, Math.random() * 100])
        ).flat()
      );
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const chartHeight = isModalOpen ? "500px" : "250px";

  return (
    <div>
      <ReactECharts option={option} style={{ height: chartHeight, width: "100%" }} />
    </div>
  );
};

export default Bar3DChart;
