// import { useSimulation } from 'pages/simulation'
import { useSimulation } from '../SimulationContext'
import { useState, useContext } from 'react';
import { Steps } from '.'
import './Tasks.scss'
import { Accordion } from 'components'
import { FrontHeaderDataContext } from 'contexts/FrontHeaderDataContext';

const Tasks = () => {
  const {isisActive,changeIsISActiveStatus} = useContext(FrontHeaderDataContext);
  const {
    data,
    isSessionStarted,
    setSelectedSession,
    selectedSession,
    getInitialOngoingSessionData,
    completedTasks,
    setSceneImage,
  } = useSimulation()
  const onClickTask = (task) => {
    if (!isSessionStarted) {
      setSelectedSession(task)
    } else {
      const path = `/simulation${data.virtualPath}`
      const updatedSession = getInitialOngoingSessionData(task)
      const currentSceneImage = task.subtasks[0]?.image || task?.image
      setSelectedSession(updatedSession)
      if (currentSceneImage) {
        setSceneImage(`${path}${currentSceneImage}`)
      }
    }
  }

  const handleIsIsActivity = () => {
    changeIsISActiveStatus();
  }

  return (
    <menu className="tasks-list scrollbar">
      <Accordion className="tasks-list" title="Lunar" showIndicator={false}>
      {data?.tasks?.map((task) => {
        const completed = completedTasks?.findIndex((val) => val.name === task.name) !== -1
        const selectedTask = task.name === selectedSession?.name
        return (
          <li key={task.name} className={`${selectedTask ? 'selected' : ''} ${completed ? 'completed' : ''}`}>
            <a
              onClick={() => onClickTask(task)}
              disabled={
                completed ||
                (isSessionStarted && selectedSession?.subtasks?.findIndex((val) => val?.status === 'ongoing') !== -1)
              }>
              {task.name}
            </a>
            {selectedTask &&
            isSessionStarted &&
            selectedSession?.subtasks?.findIndex((val) => val?.status === 'ongoing') !== -1 ? (
              <Steps />
            ) : null}
          </li>
        )
      })}
      </Accordion>

      <Accordion className="tasks-list" title="Mars" showIndicator={false}>
      {data?.tasks?.map((task) => {
        const completed = completedTasks?.findIndex((val) => val.name === task.name) !== -1
        const selectedTask = task.name === selectedSession?.name
        return (
          <li key={task.name} className={`${selectedTask ? 'selected' : ''} ${completed ? 'completed' : ''}`}>
            <a
              onClick={() => onClickTask(task)}
              disabled={
                completed ||
                (isSessionStarted && selectedSession?.subtasks?.findIndex((val) => val?.status === 'ongoing') !== -1)
              }>
              {task.name}
            </a>
            {selectedTask &&
            isSessionStarted &&
            selectedSession?.subtasks?.findIndex((val) => val?.status === 'ongoing') !== -1 ? (
              <Steps />
            ) : null}
          </li>
        )
      })}
      </Accordion>
      <div className='mt-5'><li style={{listStyleType:'none',cursor:'pointer'}} onClick={() => {handleIsIsActivity()}}  className={`text-center h4 ${isisActive ? 'text-white' : ''}`}>ISIS</li></div>
    </menu>
  )
}

export default Tasks
