import React, { useState, useRef,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from 'front/Header/Header';
import Footer from 'front/Footer/Footer';
import { Canvas, useFrame, extend, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { Html } from '@react-three/drei';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {
    Button,
  
    Container,
    Row,
    Card,
    CardBody,
    Col,
  } from "reactstrap";
import { API_ASSET_URL } from 'front/Simulation/common/config';

  const bioMechanincsData = {
    "data": [
      { "label": "Dynamic Postural Stability Index (PSI)", "value": "0.95", "unit": null },
      { "label": "Variability of Ground Reaction Forces (GRF)", "value": "CV/SD", "unit": null },
      { "label": "Limits of Stability", "value": "Cms", "unit": null },
      { "label": "Center of Pressure (COP) length & excursion", "value": "Cms", "unit": null },
      { "label": "Spatiotemporal Speed", "value": "m/s", "unit": null },
      { "label": "Stride Rate", "value": "Hz", "unit": null },
      { "label": "Stride Length", "value": "m", "unit": null },
      { "label": "Duration of Stance, Loading, propulsion", "value": "s/%", "unit": null },
      { "label": "Step Width", "value": "m", "unit": null },
      { "label": "Peak vertical & anterior/posterior GRF", "value": "N", "unit": null },
      { "label": "Peak Medio-lateral GRF", "value": "N", "unit": null },
      { "label": "Minimum vertical GRF", "value": "N", "unit": null },
      { "label": "Loading & Propulsion rates", "value": "N/s", "unit": null },
      { "label": "Joint Movements", "value": "N/m", "unit": null },
      { "label": "Peak Normal Force (PNF)", "value": "N", "unit": null },
      { "label": "Minimal normal force (MNF)", "value": "N", "unit": null },
      { "label": "PNF at rearfoot, midfoot and forefoot", "value": "N", "unit": null },
      { "label": "Measured Torque", "value": "Nm", "unit": null },
      { "label": "Force applied by exosuit layers", "value": "N", "unit": null },
      { "label": "Peak Joint Angles (max & min)", "value": "Degrees", "unit": null },
      { "label": "Range of Motion", "value": "Degrees", "unit": null }
    ]
  }
function Biomechanics(props) {
    const location = useLocation();

    extend({ OrbitControls });
    function AstroWalkModel({ animate }) {
        const astroWalkModelRef = useRef();
        const astroWalkmixerRef = useRef();
        const astroWalkControlsRef = useRef();
        const { scene, animations } = useGLTF(`${API_ASSET_URL}/assets/models/Metakosmos walk.glb`);
      
        useFrame((state, deltaTime) => {
          // Access currentModel directly for the latest value
          if (animate && astroWalkmixerRef.current) {
            astroWalkmixerRef.current.update(deltaTime);
          }
        });
      
        useEffect(() => {
          // Clean up any existing mixer before creating a new one
          if (astroWalkmixerRef.current) {
            astroWalkmixerRef.current.stopAllAction();
            astroWalkmixerRef.current = null;
          }
      
          if (animations && animations.length > 0 && animate) {
            const mixer = new THREE.AnimationMixer(scene);
            const action = mixer.clipAction(animations[0]);
            action.play();
            astroWalkmixerRef.current = mixer;
          }
      
          if (astroWalkModelRef.current) {
            astroWalkModelRef.current.position.y = -3;
            astroWalkModelRef.current.scale.set(20, 20, 20);
          }
        }, [scene, animations, animate]); // Include currentModel in the dependency array
      
        return (
          <>
            <primitive object={scene} ref={astroWalkModelRef} />
            <orbitControls ref={astroWalkControlsRef} args={[useThree().camera, useThree().gl.domElement]} enableZoom={true} />
          </>
        );
      }
    return (
        <div>
            <Header currentRoute={location.pathname}></Header>
              <Row >
                    <Col md="12" className='m-2'>
                        <Row className='mr-3 mt-3'>
                            <Col className='' md="4">
                                <Card className='main-dashboard' style={{ background: '#14181B' }}>
                                    <div className="inner_dashboard">
                                        <span className="corner-top-left"></span>
                                        <span className="corner-top-right"></span>
                                        <CardBody>
                                        <Canvas className='kosmosuit-model'>
                                                <ambientLight />
                                                <AstroWalkModel animate={true} />
                                                </Canvas>
                                        </CardBody>
                                        <span className="corner-bottom-left"></span>
                                        <span className="corner-bottom-right"></span>
                                    </div>
                                </Card>
                            </Col>
                            <Col className='' md="8">
                                <Card className='main-dashboard' style={{ background: '#14181B' }}>
                                    <div className="inner_dashboard">
                                        <span className="corner-top-left"></span>
                                        <span className="corner-top-right"></span>
                                        <CardBody>
                                        {bioMechanincsData.data.map((item, index) => (
                                            <Row key={index} className='m-2'>
                                            <Col xs="6" sm="4">{item.label}</Col>
                                            <Col xs="6" sm="2">{item.value}</Col>
                                            <Col xs="6" sm="4">{item.unit}</Col>
                                            </Row>
                                        ))}
                                        </CardBody>
                                        <span className="corner-bottom-left"></span>
                                        <span className="corner-bottom-right"></span>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Footer></Footer>
        </div>
    );
}

export default Biomechanics;