import React from 'react';



import { Card, CardHeader, CardBody, Row, Col,CardImg,CardText, CardTitle, Table } from "reactstrap";
import { API_ASSET_URL } from 'front/Simulation/common/config';

function Data() {
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader></CardHeader>
              <CardBody>
              <div className="font-icon-detail">
                <Row>
                  <Col md="3">
                    <CardTitle tag="h3">Tier 1 Data</CardTitle>
                  </Col>
                  <Col md="3">
                    <CardTitle tag="h3">Tier 2 Data</CardTitle>
                  </Col>
                  <Col md="3">
                    <CardTitle tag="h3">Tier 3 Data</CardTitle>
                  </Col>
                  <Col md="3">
                    <CardTitle tag="h3">Tier 4 Data</CardTitle>
                  </Col>
                </Row>
                </div>

                <div className="font-icon-detail">
                <Row>
                  <Col md="4">
                  <img src={`${API_ASSET_URL}/assets/img/data/Picture1.png`}></img>
                  </Col>
                  <Col md="4">
                  <img  src={`${API_ASSET_URL}/assets/img/data/Picture2.png`}></img>
                  </Col>
                  <Col md="4">
                  <img  src={`${API_ASSET_URL}/assets/img/data/Picture3.png`}></img>
                  </Col>
                </Row>
                </div>

                <div className="font-icon-detail">
                <Row>
                  <Col md="3">
                  <img  src={`${API_ASSET_URL}/assets/img/data/Picture4.png`}></img>
                  </Col>
                  <Col md="3">
                  <img  src={`${API_ASSET_URL}/assets/img/data/Picture5.png`}></img>
                  </Col>
                  <Col md="3">
                  <img  src={`${API_ASSET_URL}/assets/img/data/Picture6.png`}></img>
                  </Col>
                  <Col md="3">
                  <img  src={`${API_ASSET_URL}/assets/img/data/Picture7.png`}></img>
                  </Col>
                </Row>
                </div>

                <Row>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Data;