import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import '../assets/css/digital-fit.css';
import { Canvas, useFrame } from 'react-three-fiber';
import classNames from "classnames";


import Slider from 'react-rangeslider'


import Webcam from 'react-webcam';

import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Input,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    Modal,
    ModalBody,
    NavbarToggler,
    ModalHeader,
    Row,
    Card,
    CardHeader,
    CardBody,
    Col,
    CardTitle,
    Table
} from "reactstrap";
import FrontNavbar from './FrontNavbar';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { API_ASSET_URL } from './Simulation/common/config';


function DigitalFit(props) {

    const location = useLocation();
    const [videoWidth, setVideoWidth] = useState(700);
    const [currentImage, setCurrentImage] = useState(`${API_ASSET_URL}/assets/digitalfit/Human_Body_Front-removebg-preview.png`);
    const [collapseOpen, setcollapseOpen] = React.useState(false);
    const [color, setcolor] = React.useState("navbar-transparent");
    const [modalDigitalFitOpn, setDigitalFitModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [webcamRef, setWebcamRef] = useState(null);
    const [activeGifModel, setActiveGifModal] = useState(false);

    const [activeButton, setActiveButton] = useState('LENGTH');

    const [isCameraOpen, setCameraOpen] = useState(false);
    const fileInputRef = useRef(null);

    const videoConstraints = {
        width: { ideal: 380 },
        height: { ideal: 350 },
        facingMode: 'user', // or 'environment' for rear camera
        aspectRatio: 16 / 9,
        frameRate: { ideal: 30 },
    };

    const toggleDigitalFitModal = () => {
        setDigitalFitModal(!modalDigitalFitOpn);
        if (!modalDigitalFitOpn) {
            setWebcamRef(null);
        }
    };

    const handleCameraOpen = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            setCameraOpen(true);

            const videoElement = document.getElementById('cameraFeed');
            if (videoElement) {
                videoElement.srcObject = stream;
                videoElement.play();
            }

            // Set up the webcam reference for capturing photos
            setWebcamRef(<Webcam audio={false} height={350} screenshotFormat="image/jpeg" width={380} videoConstraints={videoConstraints} />);
        } catch (error) {
            console.error('Error accessing camera:', error);
        }
    };

    const handleCapturePhoto = () => {
        if (webcamRef) {
            const imageSrc = webcamRef.getScreenshot();
            // Do something with the captured image (e.g., save it, display it, etc.)
            console.log('Captured Image:', imageSrc);
        }
    }

    const toggleButton = () => {
        setActiveButton(activeButton === 'LENGTH' ? 'CIRCUMFERENCE' : 'LENGTH');
    };

    const handleArrowClick = (direction) => {
        // Update the image based on the direction
        if (direction === 'left') {
            setCurrentImage(`${API_ASSET_URL}/assets/digitalfit/Human_Body_Left-removebg-preview.png`);
        } else if (direction === 'right') {
            setCurrentImage(`${API_ASSET_URL}/assets/digitalfit/Human_Body_Right-removebg-preview.png`);
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };


    useEffect(() => {
        const handleResize = () => {
            // Adjust the width based on the screen width
            const screenWidth = window.innerWidth;
            const newVideoWidth = Math.min(screenWidth, 1190); // Set a maximum width of 700
            setVideoWidth(newVideoWidth);
        };

        // Set initial width on component mount
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures the effect runs only once on mount

    const toggleCollapse = () => {
        if (collapseOpen) {
            setcolor("navbar-transparent");
        } else {
            setcolor("bg-white");
        }
        setcollapseOpen(!collapseOpen);
    };

    const handleModalOpen = () => {
        const modalContentDiv = document.querySelector('.modal-content');
        if (modalContentDiv) {
            modalContentDiv.classList.add('digitalfit-modal');
        }
    };

    return (
        <div>
            <Header  currentRoute={location.pathname}></Header>
            <Container fluid>
                <div className="content">
                    <Row className='digital-fit'>
                        <Col className='mt-5' md="12" lg="12" sm="12">
                            <Card className='rounded mt-5 content-card' style={{ border: '2px #043c5e solid' }}>
                                <CardBody style={{ background: '#14181B' }}>
                                    <Row className='mt-n3 mb-n3'>
                                        <Col className='right-border' md="5" sm="6" lg="4" style={{ borderRight: '2px #043c5e solid' }}>
                                            <Row className='mt-5 ml-1' >
                                                <Col className='create-digital' md="4" sm="4">
                                                    <span style={{ cursor: 'pointer' }} onClick={toggleDigitalFitModal}>Create Digital Fit</span>
                                                </Col>
                                                <Col className='create-digital' md="4" sm="4">
                                                    <span style={{ cursor: 'pointer' }} onClick={handleCameraOpen}>Capture 3D Scan</span>
                                                </Col>
                                                <Col className='upload-digital' md="4" sm="4">
                                                    <span style={{ cursor: 'pointer'}} onClick={handleClick}>Upload Digital Scan</span>
                                                    <Input
                                                        type="file"
                                                        onChange={handleFileChange}
                                                        style={{ display: 'none' }}
                                                        innerRef={fileInputRef}
                                                    />
                                                </Col>
                                            </Row>
                                            <Col md="8">
                                                <Row className=''>
                                                    <Col className='digital-card mt-5' md="12" sm="12" lg="12">
                                                        <label className="toggle-label">
                                                            <input type="checkbox" />
                                                            <span className="back">
                                                                <span className="toggle" />
                                                                <Col md="6" className="label on">
                                                                    LENGTH
                                                                </Col>
                                                                <Col md="6" className="label off">
                                                                    CIRCUMFERENCE
                                                                </Col>
                                                            </span>
                                                        </label>
                                                    </Col>
                                                    <Col className='m-5' md="12">
                                                        <div className='table-data'>
                                                            <Table className='digital-table'>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <Row>
                                                                                <Col md="12"><span>Axillary Arm Circumference </span></Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md="8" sm="6">
                                                                                    <input type="range" id="rangeBlue" defaultValue={133} min={0} max={255} />
                                                                                </Col>
                                                                                <Col md="1"></Col>
                                                                                <Col className='mt-1' md="2" sm="6"><span className='ml-4 xmm'>Xmm</span></Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Row>
                                                                                <Col md="12"><span>Axillary Arm Circumference </span></Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md="8" sm="6">
                                                                                    <input type="range" id="rangeBlue" defaultValue={123} min={0} max={255} />
                                                                                </Col>
                                                                                <Col md="1"></Col>
                                                                                <Col className='mt-1' md="2" sm="6"><span className='ml-4 xmm'>Xmm</span></Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Row>
                                                                                <Col md="12"><span>Axillary Arm Circumference </span></Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md="8" sm="6">
                                                                                    <input type="range" id="rangeBlue" defaultValue={103} min={0} max={255} />
                                                                                </Col>
                                                                                <Col md="1"></Col>
                                                                                <Col className='mt-1' md="2" sm="6"><span className='ml-4 xmm'>Xmm</span></Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Row>
                                                                                <Col md="12"><span>Axillary Arm Circumference </span></Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md="8" sm="6">
                                                                                    <input type="range" id="rangeBlue" defaultValue={93} min={0} max={255} />
                                                                                </Col>
                                                                                <Col md="1"></Col>
                                                                                <Col className='mt-1' md="2" sm="6"><span className='ml-4 xmm'>Xmm</span></Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Row>
                                                                                <Col md="12"><span>Axillary Arm Circumference </span></Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md="8" sm="6">
                                                                                    <input type="range" id="rangeBlue" defaultValue={133} min={0} max={255} />
                                                                                </Col>
                                                                                <Col md="1"></Col>
                                                                                <Col className='mt-1' md="2" sm="6"><span className='ml-4 xmm'>Xmm</span></Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Row>
                                                                                <Col md="12"><span>Axillary Arm Circumference </span></Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md="8" sm="6">
                                                                                    <input type="range" id="rangeBlue" defaultValue={13} min={0} max={255} />
                                                                                </Col>
                                                                                <Col md="1"></Col>
                                                                                <Col className='mt-1' md="2" sm="6"><span className='ml-4 xmm'>Xmm</span></Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Row>
                                                                                <Col md="12"><span>Axillary Arm Circumference </span></Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md="8" sm="6">
                                                                                    <input type="range" id="rangeBlue" defaultValue={73} min={0} max={255} />
                                                                                </Col>
                                                                                <Col md="1"></Col>
                                                                                <Col className='mt-1' md="2" sm="6"><span className='ml-4 xmm'>Xmm</span></Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Row>
                                                                                <Col md="12"><span>Axillary Arm Circumference </span></Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md="8" sm="6">
                                                                                    <input type="range" id="rangeBlue" defaultValue={43} min={0} max={255} />
                                                                                </Col>
                                                                                <Col md="1"></Col>
                                                                                <Col className='mt-1' md="2" sm="6"><span className='ml-4 xmm'>Xmm</span></Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                    {/* 3D Human Form that can be rotated */}

                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </Col>
                                                    <Row>
                                                        <Col className='text-center m-5' md="12" sm="12" lg="12">
                                                            <Button className='digital-button' style={{ textWrap: 'nowrap' }}>SAVE DIGITAL FIT</Button>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            </Col>
                                        </Col>
                                        <Col md="7" sm="6" lg="8" className='digitalfit-mobile digital-skelton p-5' style={{ background: '#000' }}>
                                            {/* <Row>
                          <Col md="12" style={{textAlign:'center'}}>
                          <img  height={500} src={HumanBod} />
                          </Col>
                          <Col className='m-3' md="12" style={{ textAlign: 'center' }}>
                            <i className="fa-solid fa-arrow-left" style={{ fontSize: '20px', color: 'white', marginRight: '10px' }} onClick={() => handleArrowClick('left')}></i>
                            <i className="fa-solid fa-hand-pointer" style={{ fontSize: '30px', color: 'white', margin: '0 10px' }} onClick={() => setCurrentImage(HumanBodyFront)}></i>
                            <i className="fa-solid fa-arrow-right" style={{ fontSize: '20px', color: 'white', marginLeft: '10px' }} onClick={() => handleArrowClick('right')}></i>
                            </Col>
                        </Row> */}

                                            <Row className=''>
                                                {/* <img  height={500} src={HumanBodPartImage} className='human-body'/> */}
                                                <Col md="5" style={{ marginTop: '10%' }}>
                                                    <video className='human-video' muted autoPlay playsInline loop >
                                                        <source height={'auto'} src={`${API_ASSET_URL}/assets/digitalfit/ManSkelton.mp4`} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </Col>
                                                {/* <Col md="2"></Col> */}
                                                <Col md="5" className='mt-3 body-icons'>
                                                    <Row className='human-icons'>
                                                        <Col className='mt-5' md="12">
                                                            <Row>

                                                                <Col md="6" className=''>
                                                                    <img className='gif-icons' onClick={() => setActiveGifModal(!activeGifModel)} src={`${API_ASSET_URL}/assets/digitalfit/muscles.gif`}  />
                                                                    <h6 className='text-center pr-5'>Vitals</h6>
                                                                </Col>
                                                                <Col md="6" className=''>
                                                                    <img className='gif-icons' onClick={() => setActiveGifModal(!activeGifModel)} src={`${API_ASSET_URL}/assets/digitalfit/brain.gif`}  />
                                                                    <h6 className='text-center pr-5'>Nervous System</h6>
                                                                </Col>
                                                                <Col md="6" className=''>
                                                                    <img className='gif-icons' onClick={() => setActiveGifModal(!activeGifModel)} src={`${API_ASSET_URL}/assets/digitalfit/nervous.gif`} />
                                                                    <h6 className='text-center pr-5'>Musculo Skeletal</h6>
                                                                </Col>

                                                                <Col md="6" className=''>
                                                                    <img className='gif-icons' onClick={() => setActiveGifModal(!activeGifModel)} src={`${API_ASSET_URL}/assets/digitalfit/cardiovascular.gif`}  />
                                                                    <h6 className='text-center pr-5'>Cardiovascular</h6>
                                                                </Col>
                                                                <Col md="6" className=''>
                                                                    <img className='gif-icons' onClick={() => setActiveGifModal(!activeGifModel)} src={`${API_ASSET_URL}/assets/digitalfit/respiratory.gif`}  />
                                                                    <h6 className='text-center pr-5'>Respiratory</h6>
                                                                </Col>

                                                                <Col md="6">
                                                                    <img className='gif-icons' onClick={() => setActiveGifModal(!activeGifModel)} src={`${API_ASSET_URL}/assets/digitalfit/blood-immunity-inflammation.gif`}  />
                                                                    <h6 className='text-center pr-5'>Blood, Immunity, Inflammation</h6>
                                                                </Col>
                                                                <Col md="6" className=''>
                                                                    <img className='gif-icons' onClick={() => setActiveGifModal(!activeGifModel)} src={`${API_ASSET_URL}/assets/digitalfit/liver-gastro.gif`}  />
                                                                    <h6 className='text-center pr-5'>Gastrointestinal & Liver</h6>
                                                                </Col>
                                                                <Col md="6">
                                                                    <img className='gif-icons' onClick={() => setActiveGifModal(!activeGifModel)} src={`${API_ASSET_URL}/assets/digitalfit/renal.gif`}  />
                                                                    <h6 className='text-center pr-5'>Renal</h6>
                                                                </Col>
                                                                <Col md="6" className=''>
                                                                    <img className='gif-icons' onClick={() => setActiveGifModal(!activeGifModel)} src={`${API_ASSET_URL}/assets/digitalfit/endocrine.gif`} />
                                                                    <h6 className='text-center pr-5'>Endocrine</h6>
                                                                </Col>
                                                                <Col md="6">
                                                                    <img className='gif-icons' onClick={() => setActiveGifModal(!activeGifModel)} src={`${API_ASSET_URL}/assets/digitalfit/dna-gifs.gif`}  />
                                                                    <h6 className='text-center pr-5'>DNA</h6>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>



       <div className={`modal ${(activeGifModel) ? 'active' : ''}`}>
        <h3 className="modal-header" />
        <div className="modal-main border">
          <div className="modal-container">
            <label>Please upload the  records</label>
            {/* <label>Changing your session settings will end your session and take you to the session set up screen.</label> */}
            {/* <label className="info-bold">Do you wish to end you session?</label> */}
            <Button onClick={() => {setActiveGifModal(!activeGifModel)}}>
              END SESSION
            </Button>
            <Button className="continue-button">CONTINUE SESSION</Button>
          </div>
        </div>
      </div>

      <div className={`modal ${(modalDigitalFitOpn) ? 'active' : ''}`}>
        <h3 className="modal-header" />
        <div className="modal-main border">
          <div className="modal-container">
           <Row className=''>
            <Col md="12" className="mb-5">
              <label>Welcome to Digital Fit</label>
             </Col>
            <Col md="12" className="mb-5">
             <label>Use the horizontal scrolls to adjust the dimensions of the digital figure.</label>
            </Col>
            {/* <Col md="12"> */}
            <Button>Start Measurement</Button>
            {/* </Col> */}
            {/* <Col md="12"> */}
             <Button className='continue-button' onClick={toggleDigitalFitModal} style={{ background: '#14181B' }}>End Session</Button>
            {/* </Col> */}
            
          </Row>
          </div>
        </div>
      </div>

            {/* <Modal
                modalClassName="modal modal-dialog-centered"
                isOpen={modalDigitalFitOpn}
                style={{ background: '#14181B' }}
                toggle={toggleDigitalFitModal}
                onOpened={handleModalOpen}
            >
                <div className="inner_digital_modal">
                    <span className="corner-top-left"></span>
                    <span className="corner-top-right"></span>
                    <ModalBody>
                        <Row className=''>
                            <Col md="12" className="mb-5">
                                <span>Welcome to Digital Fit</span>
                            </Col>
                            <Col md="12" className="mb-5">
                                <span>Use the horizontal scrolls to adjust the dimensions of the digital figure.</span>
                            </Col>
                            <div className='digital_buttons'>
                                <Col md="12">
                                    <Button className='end_session mb-2' style={{ background: '#101f2a' }}>Start Measurement</Button>
                                </Col>
                                <Col md="12">
                                    <Button className='continue_session' onClick={toggleDigitalFitModal} style={{ background: '#14181B' }}>End Session</Button>
                                </Col>
                            </div>
                        </Row>
                    </ModalBody>
                    <span className="corner-bottom-left"></span>
                    <span className="corner-bottom-right"></span>
                </div>
            </Modal> */}


            <div className={`modal ${(isCameraOpen) ? 'active' : ''}`}>
                    <h3 className="modal-header" />
                    <div className="modal-main border">
                    <div className="modal-container">
                    <Row>
                            <Col md="12">
                            
                                <a
                                    aria-label="Close"
                                    className="close mb-5"
                                    onClick={() => setCameraOpen(!isCameraOpen)}
                                >
                                    <i className="fa fa-remove text-white" />
                                </a>
                                
                                {webcamRef}
                                
                                <Col className='digital_buttons' md="12">
                                    <Button className='end_session mb-2' style={{ background: '#101f2a' }}>3D Measurement</Button>
                                </Col>
                            </Col>
                        </Row>
                    </div>
                    </div>
                </div>

            {/* <Modal
                modalClassName="modal modal-dialog-centered"
                isOpen={isCameraOpen}
                style={{ background: '#14181B' }}
                toggle={() => setCameraOpen(!isCameraOpen)}
                onOpened={handleModalOpen}
            >
                <div className="inner_digital_modal">
                    <span className="corner-top-left"></span>
                    <span className="corner-top-right"></span>
                    <ModalBody>
                        <Row>
                            <Col md="12">
                            
                                <button
                                    aria-label="Close"
                                    className="close mb-5"
                                    onClick={() => setCameraOpen(!isCameraOpen)}
                                >
                                    <i className="fa fa-remove text-white" />
                                </button>
                                
                                {webcamRef}
                                
                                <Col className='digital_buttons' md="12">
                                    <Button className='end_session mb-2' style={{ background: '#101f2a' }}>3D Measurement</Button>
                                </Col>
                            </Col>
                        </Row>
                    </ModalBody>
                    <span className="corner-bottom-left"></span>
                    <span className="corner-bottom-right"></span>
                </div>
            </Modal> */}
            <Footer></Footer>
        </div>
    );
}

export default DigitalFit;