import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import "echarts-gl";
import "./SurfaceChart.css"; // Import CSS for styling

const SurfaceChart = ({isModalOpen, chartData}) => {
  const [data, setData] = useState(
    Array.from({ length: 10 }, (_, x) =>
      Array.from({ length: 10 }, (_, y) => Math.random() * 100)
    )
  );

  const option = {
    title: {
      // text: "Vibration",
      // text: `${chartData?.metric ? chartData.metric : 'Vibration' }`,
      left: "center",
      textStyle: {
        fontSize: 12,
        fontFamily: "Alternox-Light",
        color: "#FFF", // Neon blue title
      },
    },
    tooltip: {
      trigger: "item",
      backgroundColor: "#344675",
      borderColor: "#00E5FF",
      textStyle: {
        color: "#FFF",
      },
    },
    xAxis3D: {
      type: "value",
      name: "X",
      nameTextStyle: { color: "#AAA" },
      axisLine: { lineStyle: { color: "#FFF" } },
    },
    yAxis3D: {
      type: "value",
      name: "Y",
      nameTextStyle: { color: "#AAA" },
      axisLine: { lineStyle: { color: "#FFF" } },
    },
    zAxis3D: {
      type: "value",
      name: "Z",
      nameTextStyle: { color: "#AAA" },
      axisLine: { lineStyle: { color: "#FFF" } },
    },
    grid3D: {
      viewControl: {
        projection: "orthographic", // Orthographic projection for clarity
        autoRotate: true, // Auto-rotate the chart
        autoRotateSpeed: 30, // Speed of auto-rotation
      },
      boxHeight: 100, // Adjust the height of the 3D box
      boxWidth: 150, // Adjust width
      boxDepth: 150, // Adjust depth
      axisPointer: { show: true },
    },
    series: [
      {
        type: "surface",
        wireframe: { show: true, lineStyle: { color: "#555" } }, // Subtle grid overlay
        data: data,
        itemStyle: {
          color: (params) => {
            const value = params.value[2]; // Use Z value for color gradient
            return `#00B294`;
          },
        },
      },
    ],
    // backgroundColor: "#1e2429", // Dark background
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setData(
        Array.from({ length: 10 }, (_, x) =>
          Array.from({ length: 10 }, (_, y) => Math.random() * 100)
        )
      );
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const chartHeight = isModalOpen ? "500px" : "250px";

  return (
    // <div className="surface-chart-container">
      <ReactECharts option={option} style={{ height: chartHeight, width: "100%" }} />
    // </div>
  );
};

export default SurfaceChart;
