import React from "react";
import "./LeftColoumn.css"
import { Button, Container, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";

// const items = [
//   "Oxygen Concentration",
//   "Carbon Dioxide Concentration",
//   "Temperature (Internal Helmet)",
//   "Humidity",
//   "Pressure (Internal Helmet)",
//   "Heart Rate",
//   "Respiratory Rate",
//   "Body Temperature",
//   "Blood Oxygen Saturation (SpO2)",
//   "Electrodermal Activity (EDA)",

// ];

function StatusList({ data }) {
    console.log(data)

    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate("/summary");
    };

    return (
        <Container fluid>
            <Row>
                <Col md="12">
                    <div className="mt-2" style={{ backgroundColor: "#222", border: "2px solid #555", borderRadius: "10px", padding: "10px", overflowY: 'auto' }}>
                        {data.slice(0, 7).map((item, index) => (
                            <div
                                key={index}
                                style={{
                                    borderBottom: index !== 6 ? "1px solid #555" : "none", // Adjusted for 10 items
                                    padding: "10px 0",
                                    textAlign: "center",
                                    color: "white",
                                    fontSize: "14px",
                                }}
                            >
                                {item.title}
                            </div>
                        ))}
                    </div>
                </Col>
                <Col className="text-center mt-2" md="12">
                    <Button onClick={handleNavigate}>More Details</Button>
                </Col>
            </Row>
        </Container>
    );
}

export default StatusList;
