import { useState } from 'react'

import './Accordion.scss'
import { API_ASSET_URL } from 'front/Simulation/common/config'

const Accordion = ({ title, children, showIndicator, click, icon, itemClassName = '', sectionClassName = '' }) => {
  const [isActive, setIsActive] = useState(false)

  const closeAccordion = () => {
    setIsActive(!isActive)
    if (click) {
      click()
    }
  }

  return (
    <div className="accordion-wrapper">
      <div className={`accordion-item ${itemClassName}`}>
        <div className={`accordion-section ${sectionClassName}`} onClick={() => closeAccordion()}>
          <label>{title}</label>
          <div>
            {showIndicator ? <div /> : null}
            {icon ? <img src={icon} className={'icon'} /> : null}
            {isActive ? <img className="rotated" src={`${API_ASSET_URL}/assets/images/arrow.svg`} /> : <img src={`${API_ASSET_URL}/assets/images/arrow.svg`} />}
          </div>
        </div>
        {isActive && <div>{children}</div>}
      </div>
    </div>
  )
}

export default Accordion
