import React, { useState } from "react";
import HeatmapChart from "../Metric_Graphs/Echart_Library/HeatmapChart";
import LineChart from "../Metric_Graphs/Echart_Library/LineChart";
import SurfaceChart from "../Metric_Graphs/Echart_Library/SurfaceChart";
import Bar3DChart from "../Metric_Graphs/Echart_Library/Bar3DChart";
import ScatterChart from "../Metric_Graphs/Echart_Library/ScatterChart";
import RadarChart from "../Metric_Graphs/Echart_Library/GraphDiagram";
import DynamicAreaChart from "../Metric_Graphs/Echart_Library/AreaChart";
import DynamicBoxPlot from "../Metric_Graphs/Echart_Library/Boxplot";
import DynamicGaugeChart from "../Metric_Graphs/Echart_Library/GuageChart";
import ThemeRiverChart from "../Metric_Graphs/Echart_Library/Parllel";
import DynamicHistogram from "../Metric_Graphs/Echart_Library/HistogramChart";
import PieChart from "../Metric_Graphs/Echart_Library/Pie";
import "../EchartDashBoard.css";
import LighttingChartAirplane from "../LightningChartGraphs/LcjsTemperature";

const BootDashboard = ({ graphData }) => {
  console.log("check",graphData)
  // State for controlling the modal
  const [selectedChart, setSelectedChart] = useState(null); // Stores the selected chart name
  const [showModal, setShowModal] = useState(false); // Controls modal visibility
  const [closeGraphs, setCloseGraph] = useState(true);


  // Chart click handler
  const handleChartClick = (chartName) => {
    setSelectedChart(chartName);
    setShowModal(true);
  };

  // Close modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedChart(null);
  };

  

  return (
    <div className="container-fluid px-3 py-2">
    {/* Top Row */}
    {closeGraphs ? (
      <div className="row g-1">

        <div
          className="col-3 px-1"
          onClick={() => {
            handleChartClick("HeatmapChart");
            setCloseGraph(false);
          }}
        >
          <div className="echart-graph echart-rounded shadow-sm"
            data-bs-toggle="tooltip"
            // data-bs-placement="top"
            title="1"  >
            <HeatmapChart chartData={graphData && graphData.number === '1' ? graphData : null} />

          </div>
        </div>


        <div className="col-3 px-1" 
         onClick={() => {
          handleChartClick("LineChart");
          setCloseGraph(false);
        }}>
          <div className="echart-graph echart-rounded shadow-sm"
            data-bs-toggle="tooltip"
            // data-bs-placement="top"
            title="2">

            <LineChart chartData={graphData && graphData.number === '2' ? graphData : null} />
          </div>
        </div>
        <div className="col-3 px-1" 
          onClick={() => {
            handleChartClick("Bar3DChart");
            setCloseGraph(false);
          }}>
          <div className="echart-graph echart-rounded shadow-sm"
            data-bs-toggle="tooltip"
            // data-bs-placement="top"
            title="3">
            <Bar3DChart chartData={graphData && graphData.number === '3' ? graphData : null} />
          </div>
        </div>
        <div className="col-3 px-1" 
               onClick={() => {
                handleChartClick("DynamicHistogram");
                setCloseGraph(false);
              }}>
          <div className="echart-graph echart-rounded shadow-sm"
            data-bs-toggle="tooltip"
            // data-bs-placement="top"
            title="4">
            <LighttingChartAirplane chartData={graphData && graphData.number === '11' ? graphData : null} />
          </div>
        </div>
      </div>
    ) : null}

    {/* Middle Row */}
    {closeGraphs ? (
      <div className="row g-1 mt-1">
        <div className="col-3 d-flex flex-column justify-content-between px-1">
          <div className="mb-1" 
                onClick={() => {
                  handleChartClick("SurfaceChart");
                  setCloseGraph(false);
                }}>
            <div className="echart-graph echart-rounded shadow-sm"
              data-bs-toggle="tooltip"
              // data-bs-placement="top"
              title="5">
              <SurfaceChart chartData={graphData && graphData.number === '5' ? graphData : null} />
            </div>
          </div>
          <div className="mb-1" 
          onClick={() => {
            handleChartClick("RadarChart");
            setCloseGraph(false);
          }}>
            <div className="echart-graph echart-rounded shadow-sm"
              data-bs-toggle="tooltip"
              // data-bs-placement="top"
              title="7">
              <RadarChart chartData={graphData && graphData.number === '7' ? graphData : null} />
            </div>
          </div>
        </div>
        <div className="col-6 px-1">
          <div
            className="central-component echart-graph echart-rounded shadow-sm d-flex align-items-center justify-content-center"
            style={{ height: "98%" }}
          >
            <div>
            <div>
            <img
              src="https://bioastroause.blob.core.windows.net/suit/components/boots/boots_0001.png"
              alt="Central Image"
              style={{ width: "100%", height: "140px", objectFit: "cover" }}
            />
            </div>
            </div>
          </div>
        </div>
        <div className="col-3 d-flex flex-column justify-content-between px-1">
          <div className="mb-1" 
               onClick={() => {
                handleChartClick("DynamicAreaChart");
                setCloseGraph(false);
              }}>
            <div className="echart-graph echart-rounded shadow-sm"
              data-bs-toggle="tooltip"
              // data-bs-placement="top"
              title="6">
              <DynamicAreaChart chartData={graphData && graphData.number === '6' ? graphData : null} />
            </div>
          </div>
          <div className="mb-1" 
           onClick={() => {
            handleChartClick("DynamicBoxPlot");
            setCloseGraph(false);
          }}>
            <div className="echart-graph echart-rounded shadow-sm"
              data-bs-toggle="tooltip"
              // data-bs-placement="top"
              title="8">
              <DynamicBoxPlot chartData={graphData && graphData.number === '8' ? graphData : null} />
            </div>
          </div>
        </div>
      </div>

    ) : null}

    {/* Bottom Row */}
    {closeGraphs ? (
      <div className="row g-1 mt-1">
        <div className="col-3 px-1" 
         onClick={() => {
          handleChartClick("DynamicGaugeChart");
          setCloseGraph(false);
        }}>
          <div className="echart-graph echart-rounded shadow-sm"
            data-bs-toggle="tooltip"
            // data-bs-placement="top"
            title="9">
            <DynamicGaugeChart chartData={graphData && graphData.number === '9' ? graphData : null} />
          </div>
        </div>
        <div className="col-3 px-1" 
        onClick={() => {
          handleChartClick("ThemeRiverChart");
          setCloseGraph(false);
        }}>
          <div className="echart-graph echart-rounded shadow-sm"
            data-bs-toggle="tooltip"
            // data-bs-placement="top"
            title="10">
            <ThemeRiverChart chartData={graphData && graphData.number === '10' ? graphData : null} />
          </div>
        </div>
        <div className="col-3 px-1" 
             onClick={() => {
              handleChartClick("ScatterChart");
              setCloseGraph(false);
            }}>
          <div className="echart-graph echart-rounded shadow-sm"
            data-bs-toggle="tooltip"
            // data-bs-placement="top"
            title="11">
            <ScatterChart chartData={graphData && graphData.number === '4' ? graphData : null} />
          </div>
        </div>
        <div className="col-3 px-1" 
             onClick={() => {
              handleChartClick("PieChart");
              setCloseGraph(false);
            }}>
          <div className="echart-graph echart-rounded shadow-sm"
            data-bs-toggle="tooltip"
            // data-bs-placement="top"
            title="12">
            <PieChart chartData={graphData && graphData.number === '12' ? graphData : null} />
          </div>
        </div>
      </div>
    ) : null}

    {/* Modal */}
    {showModal && (
      <div className="modal show d-block" tabIndex="-1">
        <div
          className="modal-dialog modal-lg modal-xl modal-xxl modal-dialog-centered"
          style={{ top: '-30%' }}
        >
          <div className="modal-content border">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close btn"
                onClick={() => {
                  handleCloseModal();
                  setCloseGraph(true);
                }}
              >
                X
              </button>
            </div>
            <div className="modal-body">
              {selectedChart === "HeatmapChart" && (
                <HeatmapChart
                  isModalOpen={true}
                  chartData={graphData && graphData.number === '1' ? graphData : null}
                />
              )}
              {selectedChart === "LineChart" && (
                <LineChart
                  isModalOpen={true}
                  chartData={graphData && graphData.number === '2' ? graphData : null}
                />
              )}
              {selectedChart === "Bar3DChart" && (
                <Bar3DChart
                  isModalOpen={true}
                  chartData={graphData && graphData.number === '3' ? graphData : null}
                />
              )}
              {selectedChart === "ScatterChart" && (
                <ScatterChart
                  isModalOpen={true}
                  chartData={graphData && graphData.number === '4' ? graphData : null}
                />
              )}
              {selectedChart === "SurfaceChart" && (
                <SurfaceChart
                  isModalOpen={true}
                  chartData={graphData && graphData.number === '5' ? graphData : null}
                />
              )}
              {selectedChart === "RadarChart" && (
                <RadarChart
                  isModalOpen={true}
                  chartData={graphData && graphData.number === '7' ? graphData : null}
                />
              )}
              {selectedChart === "DynamicAreaChart" && (
                <DynamicAreaChart
                  isModalOpen={true}
                  chartData={graphData && graphData.number === '6' ? graphData : null}
                />
              )}
              {selectedChart === "DynamicBoxPlot" && (
                <DynamicBoxPlot
                  isModalOpen={true}
                  chartData={graphData && graphData.number === '8' ? graphData : null}
                />
              )}
              {selectedChart === "DynamicGaugeChart" && (
                <DynamicGaugeChart
                  isModalOpen={true}
                  chartData={graphData && graphData.number === '9' ? graphData : null}
                />
              )}
              {selectedChart === "ThemeRiverChart" && (
                <ThemeRiverChart
                  isModalOpen={true}
                  chartData={graphData && graphData.number === '10' ? graphData : null}
                />
              )}
              {selectedChart === "DynamicHistogram" && (
                <DynamicHistogram
                  isModalOpen={true}
                  chartData={graphData && graphData.number === '11' ? graphData : null}
                />
              )}
              {selectedChart === "PieChart" && (
                <PieChart
                  isModalOpen={true}
                  chartData={graphData && graphData.number === '12' ? graphData : null}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )}

  </div>
  );
};

export default BootDashboard;

