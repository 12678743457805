import React, { useState, useRef,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from 'front/Header/Header';
import Footer from 'front/Footer/Footer';
import { Canvas, useFrame, extend, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { Html } from '@react-three/drei';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {
    Button,
  
    Container,
    Row,
    Card,
    CardBody,
    Col,
  } from "reactstrap";
import { API_ASSET_URL } from 'front/Simulation/common/config';

  const bioMedical = [
    { "label": "Energy Expenditure", "value": null, "unit": null },
    { "label": "Metabolic Rate", "value": "Kcal/Minute", "unit": null },
    { "label": "Body Temperature", "value": "39°C", "unit": null },
    { "label": "Galvanic Skin Response", "value": "micro siemens (uS)", "unit": null },
    { "label": "INJURY PROTECTION", "value": null, "unit": null },
    { "label": "Kinematics-Peak Joint Angles (max & min)", "value": "◦", "unit": null },
    { "label": "Kinematics -Range of Motion", "value": "◦", "unit": null },
    { "label": "Force applied /measured by devices", "value": "N", "unit": null },
    { "label": "Torque measured by devices", "value": "Nm", "unit": null },
    { "label": "Internal joint moments", "value": "Nm", "unit": null },
    { "label": "Muscle forces", "value": "N/Kg", "unit": null },
    { "label": "VITALS", "value": null, "unit": null },
    { "label": "ECG", "value": "Beats Per Minute (BPM)", "unit": null },
    { "label": "Blood Pressure", "value": "mm HG", "unit": null },
    { "label": "Breathing", "value": "Respiration Per Minute (RPM)", "unit": null },
    { "label": "EMG", "value": "milli volt (mV)", "unit": null },
    { "label": "Position", "value": "mV/g", "unit": null },
    { "label": "Pulse & Oxygen", "value": "mm HG (SpO2)", "unit": null },
    { "label": "Carbon Dioxide", "value": "mm HG", "unit": null },
    { "label": "Glucose", "value": "mg/DL", "unit": null },
    { "label": "Radiation- Muscle Loss", "value": null, "unit": null },
    // Add more data here...
  ];
function BioMedical(props) {
    const location = useLocation();
    extend({ OrbitControls });
    function WalkTwoModel({ animate }) {
        const walkModelRef = useRef();
        const mixerRef = useRef();
        const walkControlsRef = useRef();
        const { scene, animations } = useGLTF(`${API_ASSET_URL}/assets/models/Walk-new.glb`);
      
        useFrame((state, deltaTime) => {
          // Access currentModel directly for the latest value
          if (animate && mixerRef.current) {
            mixerRef.current.update(deltaTime);
          }
        });
      
        useEffect(() => {
          // Clean up any existing mixer before creating a new one
          if (mixerRef.current) {
            mixerRef.current.stopAllAction();
            mixerRef.current = null;
          }
      
          if (animations && animations.length > 0 && animate) {
            const mixer = new THREE.AnimationMixer(scene);
            const action = mixer.clipAction(animations[0]);
            action.play();
            mixerRef.current = mixer;
          }
      
          if (walkModelRef.current) {
            walkModelRef.current.position.y = -3;
            walkModelRef.current.scale.set(3, 3, 3);
          }
        }, [scene, animations, animate]); // Include currentModel in the dependency array
      
        return (
          <>
            <primitive object={scene} ref={walkModelRef} />
            <orbitControls ref={walkControlsRef} args={[useThree().camera, useThree().gl.domElement]} enableZoom={true} />
          </>
        );
      }
    return (
        <div>
             <Header currentRoute={location.pathname}></Header>
              <Row >
                    <Col md="12" className='m-2'>
                        <Row className='mr-3 mt-3'>
                            <Col className='' md="4">
                                <Card className='main-dashboard' style={{ background: '#14181B' }}>
                                    <div className="inner_dashboard">
                                        <span className="corner-top-left"></span>
                                        <span className="corner-top-right"></span>
                                        <CardBody>
                                        <Canvas className='model'>
                                                <ambientLight />
                                                <WalkTwoModel animate={true} />
                                                </Canvas>
                                        </CardBody>
                                        <span className="corner-bottom-left"></span>
                                        <span className="corner-bottom-right"></span>
                                    </div>
                                </Card>
                            </Col>
                            <Col className='' md="8">
                                <Card className='main-dashboard' style={{ background: '#14181B'}}>
                                    <div className="inner_dashboard">
                                        <span className="corner-top-left"></span>
                                        <span className="corner-top-right"></span>
                                        <CardBody>
                                        {bioMedical.map((item, index) => (
                                            <Row key={index} className='m-1'>
                                            <Col xs="6" sm="4">{item.label}</Col>
                                            <Col xs="6" sm="2">{item.value}</Col>
                                            <Col xs="6" sm="4">{item.unit}</Col>
                                            </Row>
                                        ))}
                                        </CardBody>
                                        <span className="corner-bottom-left"></span>
                                        <span className="corner-bottom-right"></span>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Footer></Footer>
        </div>
    );
}

export default BioMedical;