import React from 'react'



import { Card, CardHeader, CardBody, Row, Col,CardTitle,Table } from "reactstrap";
import { API_ASSET_URL } from 'front/Simulation/common/config';
function MetricDefination() {
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
          <Card>
              <CardHeader>
              <h2>HUMAN SPACEFLIGHT</h2>
              </CardHeader>
              <CardBody>
               <Row>
                <Col md="1"></Col>
                <Col md="2">
                <h2 className='text-nowrap' style={{color:'yellow'}}>PREFLIGHT </h2>
                </Col>
               </Row>
               <Row style={{textAlign:'center'}}>
                <Col md="3">
                <h3>ANALOG</h3>
                </Col>
                <Col md="3">
                <h3>NEUTRAL BUOYANCY</h3>
                </Col>
                <Col md="3">
                <h3>CENTRIFUGE</h3>
                </Col>
                <Col md="3">
                <h3>MOCK PLANET</h3>
                </Col>
               </Row>
               
               <br></br><br></br>
               <br></br><br></br>
               <Row>
                <Col md="1"></Col>
                <Col md="2">
                <h2 style={{color:'yellow'}}>INFLIGHT </h2>
                </Col>
               </Row>
               <Row style={{textAlign:'center'}}>
               <Col md="3">
                <img src={`${API_ASSET_URL}/assets/img/downloads/Flight.png`} width={'50%'}></img>
                </Col>
                <Col md="3">
                <img src={`${API_ASSET_URL}/assets/img/downloads/Microgravity.png`}></img>
                </Col>
                <Col md="3">
                <img src={`${API_ASSET_URL}/assets/img/downloads/Moon.jpg`}></img>
                </Col>
                <Col md="3">
                <img src={`${API_ASSET_URL}/assets/img/downloads/Mars.jpg`}></img>
                </Col>
               </Row>

               <br></br><br></br>
               <br></br><br></br>
               <Row>
                <Col md="1"></Col>
                <Col md="2">
                <h2 className='text-nowrap' style={{color:'yellow'}}>POST FLIGHT </h2>
                </Col>
               </Row>
               <Row style={{textAlign:'center'}}>
                <Col md="4">
                <h3>RADIATION IMPACT</h3>
                </Col>
                <Col md="4">
                <h3>VITALS MONITORING</h3>
                </Col>
                <Col md="4">
                <h3>STREGTH TRAINING</h3>
                </Col>
               </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default MetricDefination