
import React, { useState, useRef,useEffect } from 'react';
import { useLocation } from 'react-router-dom';


import FrontNavbar from '../FrontNavbar';
import 'assets/css/simulate.css';
import classNames from "classnames";
import { Link } from 'react-router-dom';
import { Canvas, useFrame, extend, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { Html } from '@react-three/drei';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

// reactstrap components
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Input,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    Modal,
    NavbarToggler,
    ModalHeader,
    Row,
    Card,
    CardHeader,
    CardBody,
    Col,
    CardTitle,
    Table
} from "reactstrap";
import Header from 'front/Header/Header';
import PlanetsCarousel from './PlanetsCarousel';
import HumanCarousel from './HumanCarousel ';
import Footer from 'front/Footer/Footer';
import { API_ASSET_URL } from './common/config';

function SimulateView(props) {
    const [isWebcamOn, setWebcamOn] = useState(false);
    const [loadingModel, setLoadingModel] = useState(false);
    const location = useLocation();

    extend({ OrbitControls });

    function MaleMuscleModel() {
      const modelRef = useRef();
      const controlsRef = useRef();
      const [currentModel, setCurrentModel] = useState('Walk 2');
      // Use useFrame directly inside the functional component body
      useFrame(() => {
        // Rotate the model continuously
        // modelRef.current.rotation.y += 0.01;
      });
    
    const { scene: scene1 } = useGLTF(`${API_ASSET_URL}/assets/models/Male muscle.glb`);
    
      useEffect(() => {
        if (modelRef.current) {
          modelRef.current.position.y = -3.5; // Move down by 2 units
          modelRef.current.scale.set(3.5, 3.5, 3.5); // Scale by a factor of 2 in all dimensions
        }
      }, [scene1]);
      return (
        <>
          <primitive object={scene1} ref={modelRef} />
          <orbitControls ref={controlsRef} args={[useThree().camera, useThree().gl.domElement]} enableZoom={true} />
        </>
      );
    }
    
    function AstroStaticModel() {
        const astroStaticModelRef = useRef();
        const astroStaticControlsRef = useRef();
        // Use useFrame directly inside the functional component body
        useFrame(() => {
          // Rotate the model continuously
          // modelRef.current.rotation.y += 0.01;
        });
      
      const { scene: scene2 } = useGLTF(`${API_ASSET_URL}/assets/models/Metakosmos.glb`);
      
        useEffect(() => {
          if (astroStaticModelRef.current) {
            astroStaticModelRef.current.position.y = -3; // Move down by 2 units
            astroStaticModelRef.current.scale.set(23, 23, 23); // Scale by a factor of 2 in all dimensions
    
             // Create a white material
        //   const whiteMaterial = new THREE.MeshBasicMaterial({ color: 0x606060 });
    
          // Apply the white material to all the meshes in the model
        //   scene2.traverse((child) => {
        //     if (child.isMesh) {
        //       child.material = whiteMaterial;
        //     }
        //   });
            
          }
        }, [scene2]);
        return (
          <>
            <primitive object={scene2} ref={astroStaticModelRef} />
            <orbitControls ref={astroStaticControlsRef} args={[useThree().camera, useThree().gl.domElement]} enableZoom={true} />
          </>
        );
      }

      
    return (
        <div>
            <Header currentRoute={location.pathname}></Header>
            <Container fluid style={{background:'#191f24'}}>
                <div className="content">
                    <Row className='simulation'>
                        <Col className='mt-5' md="2" style={{  }}>
                            <Card className='rounded mt-5 content-card' style={{border: '2px #043c5e solid'}}>
                                <CardBody style={{ background: '#14181B' }}>
                                    <Row className=''>
                                        <Col className='mt-2' md="12" style={{borderBottom: '2px #043c5e solid'}}>
                                            <p className=''>Select a Test</p>
                                        </Col>

                                        <Col className='mt-3' md="12">
                                            <h6>RANGE OF MOTION</h6>
                                        </Col>
                                        <Col md="12">
                                            <p>Visor Reach</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Cross Shoulder Elbow Touch</p>
                                        </Col>
                                        <Col className='mt-2' md="12" style={{borderBottom: '2px #043c5e solid'}}>
                                            <p>Recovery from Supine</p>
                                        </Col>
                                        <Col className='mt-3' md="12">
                                            <h6>SPACE STATION TASKS</h6>
                                        </Col>
                                        <Col md="12">
                                            <p>Remove Tools from Suit</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Suit Dust Removal</p>
                                        </Col>
                                        <Col className='mt-2' md="12" style={{borderBottom: '2px #043c5e solid'}}>
                                            <p>Self Don/Doff Helmet-Gloves</p>
                                        </Col>
                                        <Col className='mt-3' md="12">
                                            <h6>SURFACE OPS</h6>
                                        </Col>
                                        <Col md="12">
                                            <p>Descend a Ladder</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Push/Pull a Cart</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Carry a Sample</p>
                                        </Col>
                                        {/* duplicate  */}

                                        <Col className='mt-2' md="12" style={{borderBottom: '2px #043c5e solid'}}>
                                            <p>Self Don/Doff Helmet-Gloves</p>
                                        </Col>

                                        <Col md="12">
                                            <Button className='simulation-button'>Add Task</Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="10">
                            <Container fluid>
                                <Row className=''>
                                    <Col md="2"></Col>
                                    <Col className='mt-5' md="4">
                                    <Card className='mt-5' style={{border: '2px #043c5e solid'}}>
                                            <CardBody style={{ background: '#14181B' }}>
                                                {/* <HumanCarousel /> */}
                                                <h6 className='text-center'>AI MUSCULOSKELETAL ESTIMATION</h6>
                                            {/* <img className='simulation-image' src={Picture2}></img> */}
                                            <Canvas className='model'>
                                                <ambientLight />
                                                <MaleMuscleModel />
                                                </Canvas>
                                            </CardBody>
                                        </Card>
                                        <Row className='text-center'>
                                        <Col md="12">
                                       <Link to="/simulate/analytics">
                                        <Button  className='simulation-button'>
                                        ANALYTICS
                                        </Button>
                                        </Link>
                                        &nbsp; &nbsp; &nbsp;
                                        <Link to="/simulate-state/">
                                        <Button  className='simulation-button'>
                                        View
                                        </Button>
                                        </Link>
                                    </Col>
                                        </Row>
                                    </Col>
                                    <Col className='mt-5' md="4">
                                    <Card className='mt-5' style={{border: '2px #043c5e solid'}}>
                                            <CardBody style={{ background: '#14181B' }}>
                                            {/* <PlanetsCarousel /> */}
                                                <h6 className='text-center'>AI KOSMOSUIT ESTIMATION</h6>
                                                {/* <img className='simulation-image' src={Picture3}></img> */}
                                                <Canvas className='model'>
                                                <ambientLight />
                                                <AstroStaticModel />
                                                </Canvas>
                                            </CardBody>
                                        </Card>
                                        <Row className='text-center'>
                                        <Col md="12" lg="12">
                                        <Button className='simulation-button'>SAVE SESSION</Button>
                                        </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </div>
                <Footer></Footer>
            </Container>
        </div>
    );
}

export default SimulateView;