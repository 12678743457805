
import { API_ASSET_URL } from 'front/Simulation/common/config'


export const accordionData = [
  {
    id: 1,
    name: 'Suit Configuration',
    icon: `${API_ASSET_URL}/assets/images/SuitConfigurationIcon.gif`,
    content: [
      {
        id: 11,
        name: 'Suit Operating Pressure',
      },
      {
        id: 12,
        name: 'Suit Weight',
      },
      {
        id: 13,
        name: 'Internal Surface Temp',
      },
      {
        id: 14,
        name: 'Life Support',
        children: [
          {
            id: 141,
            name: 'Oxygen',
          },
          {
            id: 142,
            name: 'Water',
          },
        ],
      },
      {
        id: 15,
        name: 'Secondary Life Support',
        children: [
          {
            id: 151,
            name: 'Oxygen',
          },
          {
            id: 152,
            name: 'Water',
          },
        ],
      },
      {
        id: 16,
        name: 'Battery Module',
        children: [
          {
            id: 161,
            name: 'Battery Cells',
          },
          {
            id: 162,
            name: 'Capacity',
          },
        ],
      },
      {
        id: 17,
        name: 'Tool Kit',
        children: [
          {
            id: 171,
            name: 'Geology Hammer',
          },
          {
            id: 172,
            name: 'Rake',
          },
          {
            id: 173,
            name: 'Scoop',
          },
          {
            id: 174,
            name: 'Tongs',
          },
          {
            id: 175,
            name: 'Extension Handle',
          },
          {
            id: 176,
            name: 'Contingency Sampler',
          },
          {
            id: 177,
            name: 'Drive Tube',
          },
          {
            id: 178,
            name: 'Sample Bag',
          },
          {
            id: 179,
            name: 'Bags',
          },
          {
            id: 1791,
            name: 'Chisel',
          },
          {
            id: 1792,
            name: 'Scale',
          },
          {
            id: 1793,
            name: 'Suit to Tool Interface Kit',
          },
          {
            id: 1794,
            name: 'Flag',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'Biomedical',
    icon: `${API_ASSET_URL}/assets/images/BiomedicalIcon.gif`,
    content: [
      {
        id: 21,
        name: 'Sensorsuit Properties',
        children: [
          {
            id: 211,
            name: 'Hydraulic Resistance',
          },
          {
            id: 212,
            name: 'Water Flow Rate',
          },
          {
            id: 213,
            name: 'Compression Pressure',
          },
          {
            id: 214,
            name: 'Thermal Conductivity',
          },
          {
            id: 215,
            name: 'Mass Flow Rate',
          },
          {
            id: 216,
            name: 'Heat Transfer Rate',
          },
          {
            id: 217,
            name: 'Fabric Properties',
          },
          {
            id: 218,
            name: 'Liquid Properties - Temperature',
          },
        ],
      },
      {
        id: 22,
        name: 'Temperature Regulation',
        children: [
          {
            id: 221,
            name: 'Metabolic Rate',
          },
          {
            id: 222,
            name: 'Body Temperature',
          },
          {
            id: 223,
            name: 'Galvanic Skin Response',
          },
        ],
      },
      {
        id: 23,
        name: 'INJURY PROTECTION',
        children: [
          {
            id: 231,
            name: 'Kinematics-Peak Joint Angles ( max & min)',
          },
          {
            id: 232,
            name: 'Kinematics -Range of Motion',
          },
          {
            id: 233,
            name: 'Force applied /measured by devices',
          },
          {
            id: 234,
            name: 'Torque measured by devices',
          },
          {
            id: 235,
            name: 'Internal joint moments',
          },
          {
            id: 236,
            name: 'Muscle forces',
          },
        ],
      },
      {
        id: 24,
        name: 'BIOMARKERS',
        children: [
          {
            id: 241,
            name: 'ECG',
          },
          {
            id: 242,
            name: 'Blood Pressure',
          },
          {
            id: 243,
            name: 'Breathing',
          },
          {
            id: 244,
            name: 'EMG',
          },
          {
            id: 245,
            name: 'Position',
          },
          {
            id: 246,
            name: 'Pulse & Oxygen',
          },
          {
            id: 247,
            name: 'Carbon Dioxide',
          },
          {
            id: 248,
            name: 'Glucose',
          },
        ],
      },
      {
        id: 25,
        name: 'Radiation- Muscle Loss',
        children: [
          {
            id: 251,
            name: '3 Methlyl Histidine',
          },
          {
            id: 252,
            name: 'Creatinine',
          },
        ],
      },
      {
        id: 26,
        name: 'Radiation- Bone loss',
        children: [
          {
            id: 261,
            name: 'Pyridinoline',
          },
          {
            id: 262,
            name: 'Deoxyhydroxylysine',
          },
          {
            id: 263,
            name: 'Hydroxylysine',
          },
          {
            id: 264,
            name: 'BSP',
          },
          {
            id: 265,
            name: 'Hydroxyproline',
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: 'Environment',
    icon: `${API_ASSET_URL}/assets/images/EnvironmentIcon.gif`,
    content: [
      {
        id: 31,
        name: 'Earth',
        children: [
          {
            id: 311,
            name: 'Surface Radiation',
          },
          {
            id: 312,
            name: 'Gravity',
          },
          {
            id: 313,
            name: 'Atmospheric Gases',
          },
          {
            id: 314,
            name: 'Wind Speed',
          },
          {
            id: 315,
            name: ' Mean Temperature',
          },
          {
            id: 316,
            name: 'Surface Pressure',
          },
          {
            id: 317,
            name: 'Length of Day',
          },
          {
            id: 318,
            name: 'Water  Depth',
          },
        ],
      },
      {
        id: 32,
        name: 'Moon',
        children: [
          {
            id: 321,
            name: 'Surface Radiation',
          },
          {
            id: 322,
            name: 'Gravity',
          },
          {
            id: 323,
            name: 'Atmospheric Gases',
          },
          {
            id: 324,
            name: 'Wind Speed',
          },
          {
            id: 325,
            name: ' Mean Temperature',
          },
          {
            id: 326,
            name: 'Surface Pressure',
          },
          {
            id: 327,
            name: 'Length of Day',
          },
        ],
      },
      {
        id: 33,
        name: 'Mars',
        children: [
          {
            id: 331,
            name: 'Surface Radiation',
          },
          {
            id: 332,
            name: 'Gravity',
          },
          {
            id: 333,
            name: 'Atmospheric Gases',
          },
          {
            id: 334,
            name: 'Wind Speed',
          },
          {
            id: 335,
            name: ' Mean Temperature',
          },
          {
            id: 336,
            name: 'Surface Pressure',
          },
          {
            id: 337,
            name: 'Length of Day',
          },
        ],
      },
      {
        id: 34,
        name: 'Mercury',
      },
      {
        id: 35,
        name: 'Venus',
      },
      {
        id: 36,
        name: 'Jupiter',
      },
      {
        id: 37,
        name: 'Saturn',
      },
      {
        id: 38,
        name: 'Uranus',
      },
      {
        id: 39,
        name: 'Neptune',
      },
      {
        id: 3911,
        name: 'Exoplanets',
      },
      {
        id: 3912,
        name: 'Asteroids',
      },
    ],
  },
  {
    id: 4,
    name: 'Cognitive',
    icon:`${API_ASSET_URL}/assets/images/CognitiveIcon.gif`,
    content: [
      {
        id: 41,
        name: 'EEG',
        children: [
          {
            id: 411,
            name: 'Beta',
          },
          {
            id: 412,
            name: 'Alpha',
          },
          {
            id: 413,
            name: 'Theta',
          },
          {
            id: 414,
            name: 'Delta',
          },
          {
            id: 415,
            name: 'Gamma',
          },
        ],
      },
      {
        id: 42,
        name: 'Fatigue',
      },
      {
        id: 43,
        name: 'Noise Level',
      },
      {
        id: 44,
        name: 'Response Level',
      },
    ],
  },
  {
    id: 5,
    name: 'Biomechanics',
    icon: `${API_ASSET_URL}/assets/images/BiomechanicsIcon.gif`,
    content: [
      {
        id: 51,
        name: 'Postural Stability',
        children: [
          {
            id: 511,
            name: 'Dynamic Postural Stability Index ( PSI)',
          },
          {
            id: 512,
            name: 'Variability of Ground Reaction Forces ( GRF)',
          },
          {
            id: 513,
            name: 'Limits of Stability',
          },
          {
            id: 514,
            name: 'Center of Pressure (COP) length & excursion',
          },
        ],
      },
      {
        id: 52,
        name: 'Spatiotemportal',
        children: [
          {
            id: 521,
            name: 'Speed',
          },
          {
            id: 522,
            name: 'Stride Rate',
          },
          {
            id: 523,
            name: 'Stride Length',
          },
          {
            id: 524,
            name: 'Duration of Stance, Loading , propulsion',
          },
          {
            id: 525,
            name: 'Step Width',
          },
        ],
      },
      {
        id: 53,
        name: 'Kinetics - Ground Reaction Forces',
        children: [
          {
            id: 531,
            name: 'Peak vertical & anterior /posterior GRF',
          },
          {
            id: 532,
            name: 'Peak Medio-lateral GRF',
          },
          {
            id: 533,
            name: 'Minimum vertical GRF',
          },
          {
            id: 534,
            name: 'Loading & Propulsion rates',
          },
          {
            id: 535,
            name: 'Joint Movements',
          },
        ],
      },
      {
        id: 54,
        name: 'Kinetics - Insole Forces',
        children: [
          {
            id: 541,
            name: 'Peak Normal Force ( PNF)',
          },
          {
            id: 542,
            name: 'Minimal normal force ( MNF)',
          },
          {
            id: 543,
            name: 'PNF at rearfoot, midfoot and forefoot',
          },
        ],
      },
      {
        id: 55,
        name: 'Kinetics - Exosuit',
        children: [
          {
            id: 551,
            name: 'Measured Torque',
          },
          {
            id: 552,
            name: 'Force applied by exosuit layers',
          },
        ],
      },
      {
        id: 56,
        name: 'Kinematics - Exosuit',
        children: [
          {
            id: 561,
            name: 'Peak Joint Angles ( max & min)',
          },
          {
            id: 562,
            name: 'Range of Motion',
          },
        ],
      },
    ],
  },
  {
    id: 6,
    name: 'Suit Diagnostics',
    icon: `${API_ASSET_URL}/assets/images/SuitDiagnosticsIcon.gif`,
    content: [
      {
        id: 61,
        name: 'Reach Envelope Analysis',
      },
      {
        id: 62,
        name: 'Glove Analysis',
        children: [
          {
            id: 621,
            name: 'Sensitvity',
          },
          {
            id: 622,
            name: 'Finger Tactility',
          },
          {
            id: 623,
            name: 'Grip Test',
          },
        ],
      },
      {
        id: 63,
        name: 'Suit Heat Leaks',
      },
      {
        id: 64,
        name: 'Suit Pressure Leaks',
      },
      {
        id: 65,
        name: 'Reflectivity/Emmisivity',
      },
      {
        id: 66,
        name: 'Life Support System',
        children: [
          {
            id: 661,
            name: 'Primary Oxygen',
          },
          {
            id: 662,
            name: 'Secondary Oxygen',
          },
        ],
      },
      {
        id: 67,
        name: 'HVAC System',
        children: [
          {
            id: 671,
            name: 'Chiller',
          },
          {
            id: 672,
            name: 'Water Flow',
          },
          {
            id: 673,
            name: 'Sensors',
          },
        ],
      },
      {
        id: 68,
        name: 'Power System',
        children: [
          {
            id: 681,
            name: 'State of Charge (SOC)',
          },
          {
            id: 682,
            name: 'State of Health (SOH)',
          },
        ],
      },
      {
        id: 69,
        name: 'Suit Avionics (SDNX)',
        children: [
          {
            id: 691,
            name: 'Helmet',
          },
          {
            id: 692,
            name: 'Gloves',
          },
          {
            id: 693,
            name: 'Life Support',
          },
          {
            id: 694,
            name: 'Boots',
          },
          {
            id: 695,
            name: 'Comms',
          },
        ],
      },
    ],
  },
  {
    id: 7,
    name: 'EmotiBit',
    icon: `${API_ASSET_URL}/assets/images/emotibit.jpeg`,
    content: [],
  },
]

export const results = [
  { id: 11, name: 'Suit Operating Pressure', type: 'Single', unit: 'kPa', value: 21 },
  { id: 12, name: 'Suit Weight', type: 'Single', unit: 'Kg', value: 40 },
  { id: 13, name: 'Internal Surface Temp', type: 'Single', unit: '°C', value: 39 },
  { id: 141, name: 'Oxygen', type: 'Single', unit: 'Mpa', value: 10 },
  { id: 142, name: 'Water', type: 'Single', unit: 'Liters ', value: 5 },
  { id: 151, name: 'Oxygen', type: 'Single', unit: 'MPa', value: 10 },
  { id: 152, name: 'Water', type: 'Single', unit: 'Liters ', value: 2.5 },
  { id: 161, name: 'Battery Cells', type: 'Single', unit: '', value: 40 },
  { id: 162, name: 'Capacity', type: 'Single', unit: 'Watt/Hrs', value: 390 },
  { id: 211, name: 'Hydraulic Resistance', type: 'Single', unit: 'kPa', value: 5 },
  { id: 212, name: 'Water Flow Rate', type: 'Single', unit: 'Liters/Hour', value: 100 },
  { id: 213, name: 'Compression Pressure', type: 'Single', unit: 'Psi', value: 2 },
  { id: 214, name: 'Thermal Conductivity', type: 'Single', unit: 'BTU/(h⋅ft⋅°F)', value: '' },
  { id: 215, name: 'Mass Flow Rate', type: 'Single', unit: 'P/sec ', value: '' },
  { id: 216, name: 'Heat Transfer Rate', type: 'Single', unit: 'Watt', value: '' },
  { id: 217, name: 'Fabric Properties', type: 'Single', unit: 'Grams/Cm2 ', value: '' },
  { id: 218, name: 'Liquid Properties - Temperature', type: 'Single', unit: '°C', value: 4 },
  { id: 221, name: 'Metabolic Rate', type: 'Single', unit: 'Kcal/Minute', value: '' },
  { id: 222, name: 'Body Temperature', type: 'Single', unit: '°C', value: 39 },
  { id: 223, name: 'Galvanic Skin Response', type: 'Single', unit: 'micro siemens ( uS)', value: '' },
  { id: 231, name: 'Kinematics-Peak Joint Angles ( max & min)', type: 'Single', unit: '◦', value: '' },
  { id: 232, name: 'Kinematics -Range of Motion', type: 'Single', unit: '◦', value: '' },
  { id: 233, name: 'Force applied /measured by devices', type: 'Single', unit: 'N', value: '' },
  { id: 234, name: 'Torque measured by devices', type: 'Single', unit: 'Nm ', value: '' },
  { id: 235, name: 'Internal joint moments', type: 'Single', unit: 'Nm', value: '' },
  { id: 236, name: 'Muscle forces', type: 'Single', unit: 'N/Kg  ', value: '' },
  { id: 241, name: 'ECG', type: 'Single', unit: 'Beats Per Minute (BPM)', value: '' },
  { id: 242, name: 'Blood Pressure', type: 'Single', unit: 'mm HG', value: '' },
  { id: 243, name: 'Breathing', type: 'Single', unit: 'Respiration Per Minute (RPM)', value: '' },
  { id: 244, name: 'EMG', type: 'Single', unit: 'milli volt (mV)', value: '' },
  { id: 245, name: 'Position', type: 'Single', unit: 'mV/g', value: '' },
  { id: 246, name: 'Pulse & Oxygen', type: 'Single', unit: 'mm HG', value: '' },
  { id: 247, name: 'Carbon Dioxide', type: 'Single', unit: 'mm HG', value: '' },
  { id: 248, name: 'Glucose', type: 'Single', unit: 'mg/DL ', value: '' },
  { id: 251, name: '3 Methlyl Histidine', type: 'Single', unit: 'NA', value: '' },
  { id: 252, name: 'Creatinine', type: 'Single', unit: 'mg/dl ', value: '' },
  { id: 261, name: 'Pyridinoline', type: 'Single', unit: 'NA', value: '' },
  { id: 262, name: 'Deoxyhydroxylysine', type: 'Single', unit: 'NA', value: '' },
  { id: 263, name: 'Hydroxylysine', type: 'Single', unit: 'NA', value: '' },
  { id: 264, name: 'BSP', type: 'Single', unit: 'NA', value: '' },
  { id: 265, name: 'Hydroxyproline', type: 'Single', unit: 'NA', value: '' },
  { id: 311, name: 'Surface Radiation', type: 'Single', unit: 'w/m2', value: 342 },
  { id: 312, name: 'Gravity', type: 'Single', unit: 'm/s²', value: 9.807 },
  { id: 313, name: 'Atmospheric Gases', type: 'Single', unit: '% N2', value: 78 },
  { id: 314, name: 'Wind Speed', type: 'Single', unit: 'Km/Hour', value: 12 },
  { id: 315, name: 'Mean Temperature', type: 'Single', unit: '°C', value: 15 },
  { id: 316, name: 'Surface Pressure', type: 'Single', unit: 'Bar', value: 1 },
  { id: 317, name: 'Length of Day', type: 'Single', unit: 'Hours', value: 24 },
  { id: 318, name: 'Water  Depth', type: 'Single', unit: 'Meters', value: 100 },
  { id: 321, name: 'Surface Radiation', type: 'Single', unit: 'mSv/hr', value: 60 },
  { id: 322, name: 'Gravity', type: 'Single', unit: 'm/s²', value: 1.6 },
  { id: 323, name: 'Atmospheric Gases', type: 'Single', unit: '%Helium ', value: 33 },
  { id: 324, name: 'Wind Speed', type: 'Single', unit: 'Km/Hour', value: 0 },
  { id: 325, name: 'Mean Temperature', type: 'Single', unit: '°C', value: -20 },
  { id: 326, name: 'Surface Pressure', type: 'Single', unit: 'Bar', value: 0 },
  { id: 327, name: 'Length of Day', type: 'Single', unit: 'Hours', value: 708.7 },
  { id: 331, name: 'Surface Radiation', type: 'Single', unit: 'msv/year', value: 300 },
  { id: 332, name: 'Gravity', type: 'Single', unit: 'm/s²', value: 3.7 },
  { id: 333, name: 'Atmospheric Gases', type: 'Single', unit: '% CO2', value: 95 },
  { id: 334, name: 'Wind Speed', type: 'Single', unit: 'Km/Hour', value: 20 },
  { id: 335, name: 'Mean Temperature', type: 'Single', unit: '°C', value: -65 },
  { id: 336, name: 'Surface Pressure', type: 'Single', unit: 'Bar', value: 0.01 },
  { id: 337, name: 'Length of Day', type: 'Single', unit: 'Hours', value: 24.6 },
  { id: 411, name: 'Beta', type: 'Single', unit: 'Hz', value: '' },
  { id: 412, name: 'Alpha', type: 'Single', unit: 'Hz', value: '' },
  { id: 413, name: 'Theta', type: 'Single', unit: 'Hz', value: '' },
  { id: 414, name: 'Delta', type: 'Single', unit: 'Hz', value: '' },
  { id: 415, name: 'Gamma', type: 'Single', unit: 'Hz', value: '' },
  { id: 43, name: 'Noise Level', type: 'Single', unit: 'dB', value: '' },
  { id: 44, name: 'Response Level', type: 'Single', unit: 'msec', value: '' },
  { id: 511, name: 'Dynamic Postural Stability Index ( PSI)', type: 'Single', unit: '', value: 0.95 },
  { id: 512, name: 'Variability of Ground Reaction Forces ( GRF)', type: 'Single', unit: 'CV/SD', value: '' },
  { id: 513, name: 'Limits of Stability', type: 'Single', unit: 'Cms', value: '' },
  { id: 514, name: 'Center of Pressure (COP) length & excursion', type: 'Single', unit: 'Cms', value: '' },
  { id: 521, name: 'Speed', type: 'Single', unit: 'm s−1', value: '' },
  { id: 522, name: 'Stride Rate', type: 'Single', unit: 'Hz', value: '' },
  { id: 523, name: 'Stride Length', type: 'Single', unit: 'm', value: '' },
  { id: 524, name: 'Duration of Stance, Loading , propulsion', type: 'Single', unit: 's/%', value: '' },
  { id: 525, name: 'Step Width', type: 'Single', unit: 'm', value: '' },
  { id: 531, name: 'Peak vertical & anterior /posterior GRF', type: 'Single', unit: 'N', value: '' },
  { id: 532, name: 'Peak Medio-lateral GRF', type: 'Single', unit: 'N', value: '' },
  { id: 533, name: 'Minimum vertical GRF', type: 'Single', unit: 'N', value: '' },
  { id: 534, name: 'Loading & Propulsion rates', type: 'Single', unit: 'N/s', value: '' },
  { id: 535, name: 'Joint Movements', type: 'Single', unit: 'N/m', value: '' },
  { id: 541, name: 'Peak Normal Force ( PNF)', type: 'Single', unit: 'N', value: '' },
  { id: 542, name: 'Minimal normal force ( MNF)', type: 'Single', unit: 'N', value: '' },
  { id: 543, name: 'PNF at rearfoot, midfoot and forefoot', type: 'Single', unit: 'N', value: '' },
  { id: 551, name: 'Measured Torque', type: 'Single', unit: 'Nm', value: '' },
  { id: 552, name: 'Force applied by exosuit layers', type: 'Single', unit: 'N', value: '' },
  { id: 561, name: 'Peak Joint Angles ( max & min)', type: 'Single', unit: '◦', value: '' },
  { id: 562, name: 'Range of Motion', type: 'Single', unit: '◦', value: '' },
  { id: 61, name: 'Reach Envelope Analysis', type: 'Single', unit: '◦', value: '' },
  { id: 621, name: 'Sensitvity', type: 'Single', unit: 'N/m2', value: '' },
  { id: 622, name: 'Finger Tactility', type: 'Single', unit: '◦', value: '' },
  { id: 623, name: 'Grip Test', type: 'Single', unit: 'Kgs', value: '' },
  { id: 63, name: 'Suit Heat Leaks', type: 'Single', unit: 'Watts/m', value: '' },
  { id: 64, name: 'Suit Pressure Leaks', type: 'Single', unit: 'scc/m', value: '' },
  { id: 65, name: 'Reflectivity/Emmisivity', type: 'Single', unit: 'W⋅nm−1', value: '' },
  { id: 661, name: 'Primary Oxygen', type: 'Single', unit: 'LPM', value: '' },
  { id: 662, name: 'Secondary Oxygen', type: 'Single', unit: 'LPM', value: '' },
  { id: 671, name: 'Chiller', type: 'Single', unit: 'Green', value: '' },
  { id: 672, name: 'Water Flow', type: 'Single', unit: 'LPM', value: '' },
  { id: 673, name: 'Sensors', type: 'Single', unit: 'Green', value: '' },
  { id: 681, name: 'State of Charge (SOC)', type: 'Single', unit: '%', value: '' },
  { id: 682, name: 'State of Health (SOH)', type: 'Single', unit: '%', value: '' },
  { id: 691, name: 'Helmet', type: 'Single', unit: 'Green', value: '' },
  { id: 692, name: 'Gloves', type: 'Single', unit: 'Orange', value: '' },
  { id: 693, name: 'Life Support', type: 'Single', unit: 'Red', value: '' },
  { id: 694, name: 'Boots', type: 'Single', unit: 'Orange', value: '' },
  { id: 695, name: 'Comms', type: 'Single', unit: 'Green', value: '' },
  { id: 71, name: '', type: 'Single', unit: '', value: '' },
]
