
import React, { useState, useRef,useEffect,useMemo } from 'react';
import { useLocation } from 'react-router-dom';


import FrontNavbar from '../FrontNavbar';
import 'assets/css/simulate.css';
import classNames from "classnames";
import { Link } from 'react-router-dom';
import { Canvas, useFrame, extend, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { Html } from '@react-three/drei';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Header from '../Header/Header';
// reactstrap components
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Input,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    Modal,
    NavbarToggler,
    ModalHeader,
    Row,
    Card,
    CardHeader,
    CardBody,
    Col,
    CardTitle,
    Table
} from "reactstrap";
import Footer from 'front/Footer/Footer';

import VideoMotionCapture from '../Simulation/VideoMotionCapture'
import { API_ASSET_URL } from './common/config';



function Simulate(props) {
    const location = useLocation();
    extend({ OrbitControls });

function MaleMuscleModel() {
  const modelRef = useRef();
  const controlsRef = useRef();
  const [currentModel, setCurrentModel] = useState('Walk 2');
  // Use useFrame directly inside the functional component body
  useFrame(() => {
    // Rotate the model continuously
    // modelRef.current.rotation.y += 0.01;
  });

const { scene: scene1 } = useGLTF(`${API_ASSET_URL}/assets/models/Male muscle.glb`);

  useEffect(() => {
    if (modelRef.current) {
      modelRef.current.position.y = -3.5; // Move down by 2 units
      modelRef.current.scale.set(3.5, 3.5, 3.5); // Scale by a factor of 2 in all dimensions
    }
  }, [scene1]);
  return (
    <>
      <primitive object={scene1} ref={modelRef} />
      <orbitControls ref={controlsRef} args={[useThree().camera, useThree().gl.domElement]} enableZoom={true} />
    </>
  );
}

function AstroStaticModel() {
    const astroStaticModelRef = useRef();
    const astroStaticControlsRef = useRef();
    // Use useFrame directly inside the functional component body
    useFrame(() => {
      // Rotate the model continuously
      // modelRef.current.rotation.y += 0.01;
    });
  
  const { scene: scene2 } = useGLTF(`${API_ASSET_URL}/assets/models/Metakosmos.glb`);
  
    useEffect(() => {
      if (astroStaticModelRef.current) {
        astroStaticModelRef.current.position.y = -3; // Move down by 2 units
        astroStaticModelRef.current.scale.set(23, 23, 23); // Scale by a factor of 2 in all dimensions

         // Create a white material
      // const whiteMaterial = new THREE.MeshBasicMaterial({ color: 0x606060 });

      // Apply the white material to all the meshes in the model
      // scene2.traverse((child) => {
      //   if (child.isMesh) {
      //     child.material = whiteMaterial;
      //   }
      // });
        
      }
    }, [scene2]);
    return (
      <>
        <primitive object={scene2} ref={astroStaticModelRef} />
        <orbitControls ref={astroStaticControlsRef} args={[useThree().camera, useThree().gl.domElement]} enableZoom={true} />
      </>
    );
  }

  function WalkTwoModel({ animate, slowMotion = 0.1 }) {  // `slowMotion` prop to control speed
    const walkModelRef = useRef();
    const mixerRef = useRef();
    const walkControlsRef = useRef();
    const { scene, animations } = useGLTF(`${API_ASSET_URL}/assets/models/Walk-new.glb`);
  
    useFrame((state, deltaTime) => {
      // Access the mixer to update the animation frame by frame
      if (animate && mixerRef.current) {
        mixerRef.current.update(deltaTime);
      }
    });
  
    useEffect(() => {
      // Clean up any existing mixer before creating a new one
      if (mixerRef.current) {
        mixerRef.current.stopAllAction();
        mixerRef.current = null;
      }
  
      if (animations && animations.length > 0 && animate) {
        const mixer = new THREE.AnimationMixer(scene);
        const action = mixer.clipAction(animations[0]);
  
        // Set the speed of the animation (slow down by setting a smaller value)
        action.setEffectiveTimeScale(slowMotion);  // Slow motion factor
        action.play();
        mixerRef.current = mixer;
      }
  
      if (walkModelRef.current) {
        walkModelRef.current.position.y = -3;
        walkModelRef.current.scale.set(3, 3, 3);
      }
    }, [scene, animations, animate, slowMotion]);  // Add `slowMotion` to the dependency array
  
    return (
      <>
        <primitive object={scene} ref={walkModelRef} />
        <orbitControls ref={walkControlsRef} args={[useThree().camera, useThree().gl.domElement]} enableZoom={true} />
      </>
    );
  }

  function AstroWalkModel({ animate, slowMotion = 0.1 }) {
    const astroWalkModelRef = useRef();
    const astroWalkmixerRef = useRef();
    const astroWalkControlsRef = useRef();
    const { scene, animations } = useGLTF(`${API_ASSET_URL}/assets/models/Metakosmos walk.glb`);
  
    useFrame((state, deltaTime) => {
      // Access currentModel directly for the latest value
      if (animate && astroWalkmixerRef.current) {
        astroWalkmixerRef.current.update(deltaTime);
      }
    });
  
    useEffect(() => {
      // Clean up any existing mixer before creating a new one
      if (astroWalkmixerRef.current) {
        astroWalkmixerRef.current.stopAllAction();
        astroWalkmixerRef.current = null;
      }
  
      if (animations && animations.length > 0 && animate) {
        const mixer = new THREE.AnimationMixer(scene);
        const action = mixer.clipAction(animations[0]);

         // Set the speed of the animation (slow down by setting a smaller value)
         action.setEffectiveTimeScale(slowMotion);  // Slow motion factor
        action.play();
        astroWalkmixerRef.current = mixer;
      }
  
      if (astroWalkModelRef.current) {
        astroWalkModelRef.current.position.y = -3;
        astroWalkModelRef.current.scale.set(20, 20, 20);
      }
    }, [scene, animations, animate]); // Include currentModel in the dependency array
  
    return (
      <>
        <primitive object={scene} ref={astroWalkModelRef} />
        <orbitControls ref={astroWalkControlsRef} args={[useThree().camera, useThree().gl.domElement]} enableZoom={true} />
      </>
    );
  }


    const videoRef = useRef(null);
    const [isWebcamOn, setWebcamOn] = useState(false);
    const [loadingModel, setLoadingModel] = useState(false);

    const currentModelComponent = useMemo(() => {
      return (
        <React.Suspense fallback={<Html>Loading 3D model...</Html>}>
          {isWebcamOn ? (
            <WalkTwoModel animate={isWebcamOn} />
          ) : (
            <MaleMuscleModel />
          )}
        </React.Suspense>
      );
    }, [isWebcamOn]);

    const astroModelComponent = useMemo(() => {
        return (
          <React.Suspense fallback={<Html>Loading 3D model...</Html>}>
            {isWebcamOn ? (
              <AstroWalkModel animate={isWebcamOn} />
            ) : (
              <AstroStaticModel />
            )}
          </React.Suspense>
        );
      }, [isWebcamOn]);
  
    const toggleWebcam = async () => {
      setLoadingModel(true);
      setWebcamOn(!isWebcamOn);
      // Introduce a slight delay before updating the loadingModel state
      setTimeout(() => {
        setLoadingModel(false);
      }, 100);
    };
    return (
        <div>
            <Header  currentRoute={location.pathname}></Header>
            <Container fluid style={{background:'#191f24'}}>
                <div className="content">
                    <Row className='simulation'>
                        <Col className='mt-5' md="2">
                            <Card className='rounded mt-5 content-card' style={{border: '2px #043c5e solid'}}>
                                <CardBody style={{ background: '#14181B' }}>
                                    <Row className=''>
                                        <Col className='mt-2' md="12" style={{borderBottom: '2px #043c5e solid'}}>
                                            <p className=''>Select a Test</p>
                                        </Col>

                                        <Col className='mt-3' md="12">
                                            <h6>RANGE OF MOTION</h6>
                                        </Col>
                                        <Col md="12">
                                            <p>Visor Reach</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Cross Shoulder Elbow Touch</p>
                                        </Col>
                                        <Col className='mt-2' md="12" style={{borderBottom: '2px #043c5e solid'}}>
                                            <p>Recovery from Supine</p>
                                        </Col>
                                        <Col className='mt-3' md="12">
                                            <h6>SPACE STATION TASKS</h6>
                                        </Col>
                                        <Col md="12">
                                            <p>Remove Tools from Suit</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Suit Dust Removal</p>
                                        </Col>
                                        <Col className='mt-2' md="12" style={{borderBottom: '2px #043c5e solid'}}>
                                            <p>Self Don/Doff Helmet-Gloves</p>
                                        </Col>
                                        <Col className='mt-3' md="12">
                                            <h6>SURFACE OPS</h6>
                                        </Col>
                                        <Col md="12">
                                            <p>Descend a Ladder</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Push/Pull a Cart</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Carry a Sample</p>
                                        </Col>
                                        {/* duplicate  */}

                                        <Col className='mt-2' md="12" style={{borderBottom: '2px #043c5e solid'}}>
                                            <p>Self Don/Doff Helmet-Gloves</p>
                                        </Col>
                                        {/* <Col className='mt-3' md="12">
                                            <h6>SURFACE OPS</h6>
                                        </Col>
                                        <Col md="12">
                                            <p>Descend a Ladder</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Push/Pull a Cart</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Carry a Sample</p>
                                        </Col> */}
                                        {/* duplicate  */}
{/* 
                                        <Col className='mt-2' md="12" style={{borderBottom: '2px #043c5e solid'}}>
                                            <p>Self Don/Doff Helmet-Gloves</p>
                                        </Col> */}
                                        {/* <Col className='mt-3' md="12">
                                            <h6>SURFACE OPS</h6>
                                        </Col>
                                        <Col md="12">
                                            <p>Descend a Ladder</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Push/Pull a Cart</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Carry a Sample</p>
                                        </Col>
                                        <Col className='mt-2' md="12" style={{borderBottom: '2px #043c5e solid'}}>
                                            <p>Self Don/Doff Helmet-Gloves</p>
                                        </Col>
                                        <Col className='mt-3' md="12">
                                            <h6>SURFACE OPS</h6>
                                        </Col>
                                        <Col md="12">
                                            <p>Descend a Ladder</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Push/Pull a Cart</p>
                                        </Col>
                                        <Col md="12">
                                            <p>Carry a Sample</p>
                                        </Col> */}

                                        <Col md="12">
                                            <Button className='simulation-button'>Add Task</Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="10">
                            <Container fluid>
                                <Row className='mt-5'>
                                    <Col className='text-center' md="4">
                                        <Card className='mt-5' style={{border: '2px #043c5e solid'}}>
                                            <CardBody style={{ background: '#14181B' }}>
                                             <h6 className='text-center'>RAW CAMERA FEED</h6>
                                             {isWebcamOn ? (
                                              <VideoMotionCapture />
                                                    //  <iframe src="https://www.kernel360.kosmosuit.neurom.in/motioncapture" title="Human Activity Recognition" className='your-iframe-class' />
                                                ) : (
                                                    <img src={`${API_ASSET_URL}/assets/simulate/Picture1.jpg`} className='simulation-image' alt="Placeholder Image" />
                                                )}
                                            </CardBody>
                                        </Card>
                                        <Row className='text-center'>
                                            <Col md="12">
                                            <Button onClick={toggleWebcam} className='simulation-button'>
                                             {isWebcamOn ? 'Stop' : 'Record'}
                                             </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className='text-center' md="4">
                                        <Card className='mt-5' style={{border: '2px #043c5e solid'}}>
                                            <CardBody style={{ background: '#14181B' }}>
                                                <h6 className='text-center'>AI MUSCULOSKELETAL MOTION</h6>
                                            {/* <img className='simulation-image' src={Picture2}></img> */}
                                            <Canvas className='model'>
                                                <ambientLight />
                                                {loadingModel ? (
                                                            <Html>Loading 3D model...</Html>
                                                        ) : (
                                                            currentModelComponent
                                                        )}
                                                {/* {isWebcamOn ? <WalkTwoModel animate={isWebcamOn} /> : <MaleMuscleModel />} */}
                                                </Canvas>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col className='' md="4">
                                    <Card className='mt-5' style={{border: '2px #043c5e solid'}}>
                                            <CardBody style={{ background: '#14181B' }}>
                                                <h6 className='text-center'>REAL TIME KOSMOSUIT MOTION</h6>
                                                {/* <img className='simulation-image' src={Picture3}></img> */}
                                                <Canvas className='model'>
                                                <ambientLight intensity={0.5}  />
                                                <directionalLight position={[5, 5, 5]} intensity={0.8}  />
                                                <pointLight position={[0, 10, 0]} intensity={1} distance={50}  />
                                                {loadingModel ? (
                                                            <Html>Loading 3D model...</Html>
                                                        ) : (
                                                            astroModelComponent
                                                        )}
                                                </Canvas>
                                                
                                            </CardBody>
                                        </Card>
                                        <Row className='text-center'>
                                            <Col md="12" className='col-12'>
                                            <Link to="/simulate/view">
                                            <Button className='simulation-button'>Review</Button>
                                            </Link>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {/* <Row className=''>
                                    <Col md="4" lg="4"></Col>
                                    <Col md="4" lg="4">
                                        <Button onClick={toggleWebcam} className='simulation-button'>
                                            {isWebcamOn ? 'Stop' : 'Record'}
                                        </Button>
                                    </Col>
                                    <Col md="4" lg="4">
                                        <Link to="/simulate/view">
                                            <Button className='simulation-button'>View</Button>
                                        </Link>
                                    </Col>
                                </Row> */}
                            </Container>
                        </Col>
                    </Row>
                </div>
                <Footer></Footer>
            </Container>
        </div>
    );
}

export default Simulate;