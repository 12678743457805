import { useMemo, useState, useEffect } from 'react'

export default function useSceneLoader(baseUrl, image) {
  const [isSceneLoaded, setIsSceneLoaded] = useState(false)
  const backgroundScene = useMemo(() => new Image(), [])
  const sceneUrl = useMemo(() => new URL(image, baseUrl), [baseUrl, image])

  useEffect(() => {
    backgroundScene.onload = () => setIsSceneLoaded(true)
    backgroundScene.src = sceneUrl
  }, [sceneUrl, backgroundScene])

  return { isSceneLoaded, sceneUrl, setIsSceneLoaded }
}
