import React, { useEffect, useRef } from 'react';
import { lightningChart, PalettedFill, ColorRGBA, AxisTickStrategies, LUT, emptyFill, Themes } from '@lightningchart/lcjs';
import * as obj from 'webgl-obj-loader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { API_ASSET_URL } from 'front/Simulation/common/config';
export default function LightningChart() {
    const chartContainerRef = useRef(null);

    useEffect(() => {
        const chart = lightningChart({
            license: process.env.REACT_APP_LIGHTNINGCHART_LICENSE,
            licenseInformation: {
                appTitle: "LightningChart JS Trial",
                company: "LightningChart Ltd."
            },
        })
            .Chart3D({
                theme: Themes.darkGold,
            })
            .setBoundingBox({ x: 0.7, y: 0.5, z: 1 })
            .setTitle('Real-Time Sensor Suit Temperature');

        chart.getDefaultAxes().forEach((axis) =>
            axis.setTickStrategy(AxisTickStrategies.Numeric, (ticks) =>
                ticks
                    .setMajorTickStyle((major) => major.setLabelFillStyle(emptyFill))
                    .setMinorTickStyle((minor) => minor.setLabelFillStyle(emptyFill))
            )
        );

        fetch(`${API_ASSET_URL}/assets/models/glove  retopo.obj`) // Adjust the path as needed.
            .then((response) => response.text())
            .then((data) => {
                const modelParsed = new obj.Mesh(data);

                const model = chart
                    .addMeshModel()
                    .setScale(0.0007)
                    .setModelGeometry({
                        vertices: modelParsed.vertices,
                        indices: modelParsed.indices,
                        normals: modelParsed.vertexNormals,
                    })
                    .setHighlightOnHover(false)
                    .setName('Sensor Suit temperature');

                const palette = new PalettedFill({
                    lookUpProperty: 'value',
                    lut: new LUT({
                        units: '°C',
                        interpolate: true,
                        steps: [
                            { value: 0, color: ColorRGBA(0, 150, 255) },
                            { value: 20, color: ColorRGBA(0, 255, 0) },
                            { value: 40, color: ColorRGBA(200, 255, 0) },
                            { value: 50, color: ColorRGBA(255, 255, 0) },
                            { value: 60, color: ColorRGBA(255, 200, 0) },
                            { value: 100, color: ColorRGBA(255, 130, 0) },
                            { value: 120, color: ColorRGBA(255, 0, 0) },
                        ],
                    }),
                });


                model.setFillStyle(palette);

                const sensors = [
                    { initValue: 90, value: 100, x: -0.252, y: -0.175, z: -0.25 },
                    { initValue: 90, value: 100, x: 0.252, y: -0.175, z: -0.25 },
                    { initValue: 110, value: 100, x: -0.52, y: -0.145, z: -0.07 },
                    { initValue: 110, value: 100, x: 0.52, y: -0.145, z: -0.07 },
                    { initValue: 20, value: 20, x: 0, y: -0.1, z: -1 },
                    { initValue: 50, value: 50, x: 0, y: -0.04, z: 0.97 },
                    { initValue: 20, value: 20, x: 0, y: -0.1, z: -0.5 },
                    { initValue: 20, value: 20, x: 0, y: -0.1, z: 0 },
                    { initValue: 0, value: 0, x: 0, y: -0.1, z: 0.5 },
                ];

                const vertexCoordSensorWeights = [];

                model.setVertexValues((coordsWorld) => {
                    const vertexValues = [];

                    for (let i = 0; i < coordsWorld.length; i += 1) {
                        const locAxis = chart.translateCoordinate(coordsWorld[i], chart.coordsWorld, chart.coordsAxis);

                        const sensorWeights = new Array(sensors.length).fill(0);
                        let sumOfWeights = 0;

                        sensors.forEach((sensor, i2) => {
                            const locationDeltaX = sensor.x - locAxis.x;
                            const locationDeltaY = sensor.y - locAxis.y;
                            const locationDeltaZ = sensor.z - locAxis.z;
                            const dist = Math.sqrt(locationDeltaX ** 2 + locationDeltaY ** 2 + locationDeltaZ ** 2);
                            const weight = dist !== 0 ? 1 / dist ** 3 : 1;
                            sensorWeights[i2] = weight;
                            sumOfWeights += weight;
                        });

                        vertexCoordSensorWeights.push({ sumOfWeights, sensorWeights });

                        const vertexValue = sensors.reduce(
                            (prev, cur, i2) => prev + cur.value * sensorWeights[i2],
                            0
                        ) / sumOfWeights || 20;

                        vertexValues.push(vertexValue);
                    }

                    return vertexValues;
                });

                const frame = () => {
                    sensors.forEach((sensor) => {
                        sensor.value = sensor.initValue + 10 * Math.sin(window.performance.now() / 500);
                    });

                    model.setVertexValues(() => {
                        const vertexValues = [];

                        for (let i = 0; i < vertexCoordSensorWeights.length; i += 1) {
                            const { sumOfWeights, sensorWeights } = vertexCoordSensorWeights[i];

                            const vertexValue = sensors.reduce(
                                (prev, cur, i2) => prev + cur.value * sensorWeights[i2],
                                0
                            ) / sumOfWeights || 20;

                            vertexValues.push(vertexValue);
                        }

                        return vertexValues;
                    });

                    requestAnimationFrame(frame);
                };

                frame();

                chart.addLegendBox().add(chart);
            });

        return () => {
            chart.dispose();
        };
    }, []);

    return <div ref={chartContainerRef} style={{ width: '100%', height: '100%' }}></div>;
}
