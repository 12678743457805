import React, { useState } from "react";
import "./DashboardDropdowns.css"; // Import CSS file
import { Button } from "reactstrap";
const DashboardDropdowns = (props) => {
  const [selectedMetric, setSelectedMetric] = useState("");
  const [selectedNumber, setSelectedNumber] = useState("");

  const handleUpdate = () => {
    if (selectedMetric && selectedNumber) {
      props.onUpdate(selectedNumber, selectedMetric);
    } else {
      alert("Please select a metric and number.");
    }
  };

  return (
    <div className="dropdown-container">

       {/* Dropdown for Numbers */}
       <div className="dropdown-wrapper">
        <label htmlFor="numbers" className="dropdown-label">
          Select a Number:
        </label>
        <select
          id="numbers"
          value={selectedNumber}
          onChange={(e) => setSelectedNumber(e.target.value)}
          className="dropdown"
        >
          <option value="" disabled>
            Select a Number
          </option>
          {Array.from({ length: 12 }, (_, i) => i + 1).map((number) => (
            <option key={number} value={number}>
              {number}
            </option>
          ))}
        </select>
      </div>


      {/* Dropdown for Metrics */}
      <div className="dropdown-wrapper">
        <label htmlFor="metrics" className="dropdown-label">
          Select Metric:
        </label>
        <select
          id="metrics"
          value={selectedMetric}
          onChange={(e) => setSelectedMetric(e.target.value)}
          className="dropdown"
        >
          <option value="" disabled>
            Select a Metric
          </option>
          {props.data.map((metric, index) => (
            <option key={index} value={metric.title}>
              {metric.title}
            </option>
          ))}
        </select>
      </div>
      <Button onClick={handleUpdate} className="btn btn-secondary">
        Update Graph
      </Button>
    </div>

   
  );
};

export default DashboardDropdowns;
