import React, { useRef, Suspense, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Canvas, useLoader } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import FrontNavbar from './FrontNavbar';

import '../assets/css/dashboard.css';
import Css from '../assets/css/maindash.scss';
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
  Row,
  Card,
  CardHeader,
  CardBody,
  Col,
  CardTitle,
  Table,
  ModalBody
} from "reactstrap";

import {
  MouseWheelZoomModifier,
  NumericAxis,
  SciChartSurface,
  SplineMountainRenderableSeries,
  SplineLineRenderableSeries,
  XyDataSeries,
  ZoomExtentsModifier,
  EllipsePointMarker,
  DataPointSelectionModifier,
  SciChartJsNavyTheme,
  SweepAnimation,
  DataPointSelectionPaletteProvider,
  EAutoRange,
  libraryVersion,
  FastLineRenderableSeries,
  ZoomPanModifier
} from "scichart";
import { SciChartReact } from "scichart-react";
import { API_ASSET_URL } from './Simulation/common/config';

SciChartSurface.loadWasmFromCDN();


function MainDash(props) {

  const location = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [modalMountainChartOpen, setMountainChart] = useState(false);
  const [modalLineChartOpen, setLineChart] = useState(false);
  const [modalScrollingChartOpen, setScrollingChart] = useState(false);


  const toggleMountainChartModal = () => {
    setMountainChart(!modalMountainChartOpen)
  }

  const toggleLineChartModal = () => {
    setLineChart(!modalLineChartOpen);
  }

  const toggleScrollingChartModal = () => {
    setScrollingChart(!modalScrollingChartOpen);
  }


  const handleModalOpen = () => {
    const modalContentDiv = document.querySelector('.modal-content');
    if (modalContentDiv) {
      modalContentDiv.classList.add('maindash-modal');
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  }




  return (
    <div>
      <FrontNavbar></FrontNavbar>
      <Container style={{
        backgroundImage: `url(${API_ASSET_URL}/assets/maindash/run-4fps-V1.gif)`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        maxWidth: '100%',
        height: '100vh',
        objectFit: 'cover'
      }}>
        <Row className='main-dash mt-5'>
          {/* <Col md="12">
          <img src={MotionPicture} style={{width:'140%',maxWidth:'140%',height:'100vh',marginLeft:'-206px'}}></img>
        </Col> */}
          <Col className='digital-table mt-5' md="12">
            {/* <h1>Telemetry Data</h1> */}
            <table className='table' style={{ marginTop: '26%' }}>
              <thead>
                <tr>
                  <th>Wheel</th>
                  <th>Spin (rpm)</th>
                  <th>Steer (deg)</th>
                  <th>Susp (mm)</th>
                  <th>Load (kg)</th>
                  <th>Torque (Nm)</th>
                  <th>Force (N)</th>
                  <th>Slip (m/s)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>WheelFL</td>
                  <td>82</td>
                  <td>29.3</td>
                  <td>0.78</td>
                  <td>N</td>
                  <td>Td: 1544 Nm</td>
                  <td>Fx: -1598 N, Fy: -58 N</td>
                  <td>Sx: 1.13 m/s, Sy: 0.00 m/s</td>
                </tr>
                <tr>
                  <td>Wheel FR</td>
                  <td>113</td>
                  <td>43.0</td>
                  <td>0.71</td>
                  <td>2370</td>
                  <td>Td: 0 Nm, Tb: 0 Nm, Tr: 5 Nm</td>
                  <td>Fx: 1926 N, Fy: -16 N</td>
                  <td>Sx: -0.23 m/s, Sy: 0.00 m/s</td>
                </tr>
                <tr>
                  <td>WheelRL</td>
                  <td>358</td>
                  <td>0.0</td>
                  <td>0.69</td>
                  <td>2547</td>
                  <td>Td: 606 Nm, Tb: 0 Nm, Tr: -604 Nm</td>
                  <td>Fx: 814 N, Fy: 2012 N</td>
                  <td>Sx: 3.73 m/s, Sy: 9.54 m/s</td>
                </tr>
                <tr>
                  <td>Wheel RR</td>
                  <td>301</td>
                  <td>0.0</td>
                  <td>0.62</td>
                  <td>3338</td>
                  <td>Td: 741 Nm, Tb: 0 Nm, Tr: -743 Nm</td>
                  <td>Fx: -1465 N, Fy: 2478 N</td>
                  <td>Sx: 3.72 m/s, Sy: 6.69 m/s</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Σ</th>
                  <td>9799</td>
                  <td></td>
                  <td></td>
                  <td>(999 Kg)</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
            {/* <p>Speed (Long/lat/abs): 2.20 / 3.02 / -0.68 (3.73/7.9 km/h, 4.9 mph)</p>
  <p>Acceleration: 1.80 m/s²</p>
  <p>Angular velocity Y: -0.67 rads/s</p>
  <p>Engine (load/torque): 67.2% / 97.9 Nm [ok]</p>
  <p>Clutch (lock/torque): 100.0% / 94.9 Nm</p>
  <p>4688 rpm M</p> */}
          </Col>
          <Col md="12">
            <div className="container">

              <div className={`container ${isMenuOpen ? 'active' : ''}`}>

                <div className="skeletonDemo">
                  {/* Your skeleton demo content goes here */}
                </div>

                <div className={`radial ${isMenuOpen ? 'active' : ''}`}>
                  <a className="triggerButton" href="#" onClick={toggleMenu}>
                    <i className="fa-solid fa-location-dot faOpen"></i>
                    <i className="fa-solid fa-phone faOpen"></i>
                    <i className="fa-solid fa-comment-dots faOpen"></i>
                    <i className="fa-solid fa-xmark faClose"></i>
                  </a>
                  <ul className={`radialMenu ${isMenuOpen ? 'active' : ''}`}>
                    <li id="fa-1" className="radialItem">
                      <a href="#">
                        <i className="fa-solid fa-location-dot"></i>
                        <span>Stores Near Me</span>
                      </a>
                    </li>
                    <li id="fa-2" className="radialItem">
                      <a href="#">
                        <i className="fa-solid fa-phone"></i>
                        <span>Hotline</span>
                      </a>
                    </li>
                    <li id="fa-3" className="radialItem">
                      <a href="#">
                        <i className="fa-solid fa-comment-dots"></i>
                        <span>Live Chat</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="radial active">
              <a class="triggerButton" href="#">
                <i class="fa-solid fa-location-dot faOpen"></i>
                <i class="fa-solid fa-phone faOpen"></i>
                <i class="fa-solid fa-comment-dots faOpen"></i>
                <i class="fa-solid fa-xmark faClose"></i>
              </a>
              <ul class="radialMenu active">
                <li id="fa-1" class="radialItem">
                  <a href="#" onClick={toggleMountainChartModal}>
                    <i class="fa-solid fa-location-dot"></i>
                    <span>Stores Near Me</span>
                  </a>
                </li>
                <li id="fa-2" class="radialItem">
                  <a href="#" onClick={toggleLineChartModal}>
                    <i class="fa-solid fa-phone"></i>
                    <span>Hotline</span>
                  </a>
                </li>
                <li id="fa-3" class="radialItem">
                  <a href="#" onClick={toggleScrollingChartModal}>
                    <i class="fa-solid fa-comment-dots"></i>
                    <span>Live Chat</span>
                  </a>
                </li>
                <li id="fa-3" class="radialItem">
                  <a href="#" onClick={toggleScrollingChartModal}>
                    <i class="fa-solid fa-comment-dots"></i>
                    <span>Live Chat</span>
                  </a>
                </li>
                <li id="fa-3" class="radialItem">
                  <a href="#" onClick={toggleScrollingChartModal}>
                    <i class="fa-solid fa-comment-dots"></i>
                    <span>Live Chat</span>
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>


      <Modal
        modalClassName="modal modal-dialog-centered"
        isOpen={modalMountainChartOpen}
        style={{ background: '#14181B' }}
        toggle={toggleMountainChartModal}
        onOpened={handleModalOpen}
      >
        <div className="inner_digital_modal">
          <span className="corner-top-left"></span>
          <span className="corner-top-right"></span>
          <ModalBody>
            <Row className=''>
              <Col md="12">
                <SciChartReact
                  style={{ width: 600, height: 600 }}
                  initChart={async function (rootElement) {
                    const { sciChartSurface, wasmContext } = await SciChartSurface.create(rootElement, {
                      theme: new SciChartJsNavyTheme()
                    });

                    const xAxis = new NumericAxis(wasmContext);
                    const yAxis = new NumericAxis(wasmContext);

                    sciChartSurface.xAxes.add(new NumericAxis(wasmContext, {
                      axisTitle: "X Axis",
                    }));
                    sciChartSurface.yAxes.add(new NumericAxis(wasmContext, {
                      axisTitle: "Y Axis",
                    }));

                    sciChartSurface.xAxes.add(xAxis);
                    sciChartSurface.yAxes.add(yAxis);

                    sciChartSurface.renderableSeries.add(
                      new SplineMountainRenderableSeries(wasmContext, {
                        dataSeries: new XyDataSeries(wasmContext, {
                          xValues: [1, 2, 3, 4],
                          yValues: [1, 4, 7, 3]
                        }),
                        fill: "#3ca832",
                        stroke: "#eb911c",
                        strokeThickness: 4,
                        opacity: 0.4
                      })
                    );

                    sciChartSurface.chartModifiers.add(
                      new ZoomPanModifier({ enableZoom: true }),
                      new MouseWheelZoomModifier(),
                      new ZoomExtentsModifier()
                    );

                    return { sciChartSurface };
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <span className="corner-bottom-left"></span>
          <span className="corner-bottom-right"></span>
        </div>
      </Modal>

      <Modal
        modalClassName="modal modal-dialog-centered"
        isOpen={modalLineChartOpen}
        style={{ background: '#14181B' }}
        toggle={toggleLineChartModal}
        onOpened={handleModalOpen}
      >
        <div className="inner_digital_modal">
          <span className="corner-top-left"></span>
          <span className="corner-top-right"></span>
          <ModalBody>
            <Row className=''>
              <Col md="12">
                <SciChartReact
                  style={{ width: 600, height: 600 }}
                  initChart={async function (rootElement) {
                    const { sciChartSurface, wasmContext } = await SciChartSurface.create(rootElement, {
                      theme: new SciChartJsNavyTheme()
                    });

                    const xAxis = new NumericAxis(wasmContext);
                    const yAxis = new NumericAxis(wasmContext);

                    sciChartSurface.xAxes.add(new NumericAxis(wasmContext, {
                      axisTitle: "X Axis",
                    }));
                    sciChartSurface.yAxes.add(new NumericAxis(wasmContext, {
                      axisTitle: "Y Axis",
                    }));

                    // sciChartSurface.xAxes.add(xAxis);
                    // sciChartSurface.yAxes.add(yAxis);

                    sciChartSurface.renderableSeries.add(
                      new SplineLineRenderableSeries(wasmContext, {
                        stroke: "SteelBlue",
                        strokeThickness: 3,
                        pointMarker: new EllipsePointMarker(wasmContext, {
                          width: 20,
                          height: 20,
                          strokeThickness: 2,
                          stroke: "SteelBlue",
                          fill: "LightSteelBlue"
                        }),
                        dataSeries: new XyDataSeries(wasmContext, {
                          xValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                          yValues: [4.3, 5.3, 6, 6.3, 6, 5.2, 4.5, 4.6, 5, 6, 7, 8]
                        }),

                        animation: new SweepAnimation({ duration: 300 }),

                        paletteProvider: new DataPointSelectionPaletteProvider({ fill: "white", stroke: "white" }),
                      })
                    );



                    sciChartSurface.chartModifiers.add(
                      new ZoomPanModifier({ enableZoom: true }),
                      new MouseWheelZoomModifier(),
                      new ZoomExtentsModifier(),
                      new DataPointSelectionModifier({ allowDragSelect: true })
                    );

                    return { sciChartSurface };
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <span className="corner-bottom-left"></span>
          <span className="corner-bottom-right"></span>
        </div>
      </Modal>

      <Modal
        modalClassName="modal modal-dialog-centered"
        isOpen={modalScrollingChartOpen}
        style={{ background: '#14181B' }}
        toggle={toggleScrollingChartModal}
        onOpened={handleModalOpen}
      >
        <div className="inner_digital_modal">
          <span className="corner-top-left"></span>
          <span className="corner-top-right"></span>
          <ModalBody>
            <Row className=''>
              <Col md="12">
                <SciChartReact
                  style={{ width: 600, height: 600 }}
                  initChart={async function (rootElement) {
                    // Create a chart surface
                    const { sciChartSurface, wasmContext } = await SciChartSurface.create(rootElement, {
                      theme: new SciChartJsNavyTheme(),
                      title: "Scrolling Data using fifoCapacity",
                      titleStyle: { fontSize: 16 }
                    });

                    // For the example to work, axis must have EAutoRange.Always
                    sciChartSurface.xAxes.add(new NumericAxis(wasmContext, { autoRange: EAutoRange.Always, axisTitle: "X Axis autoranged" }));
                    sciChartSurface.yAxes.add(new NumericAxis(wasmContext, { autoRange: EAutoRange.Always, axisTitle: "Y Axis autoranged" }));

                    // Start off with N X,Y values in the series
                    const xValues = [];
                    const yValues = [];
                    let i = 0;
                    for (; i < 1000; i++) {
                      xValues.push(i);
                      yValues.push(0.2 * Math.sin(i * 0.1) - Math.cos(i * 0.01));
                    }

                    // Create a DataSeries
                    const xyDataSeries = new XyDataSeries(wasmContext, {
                      xValues,
                      yValues,
                      fifoCapacity: 1000
                    });

                    console.log(`version is ${libraryVersion}`);
                    console.log(`dataSeries.fifoCapacity is ${xyDataSeries.fifoCapacity}`);

                    // Create a renderableSeries and assign the dataSeries
                    sciChartSurface.renderableSeries.add(new FastLineRenderableSeries(wasmContext, {
                      dataSeries: xyDataSeries,
                      strokeThickness: 3,
                      stroke: "#50C7E0"
                    }));

                    // Now let's use a timeout to appendRange() new values every 20ms.
                    // using removeRange() causes the number of points in the series to remain fixed and the chart to scroll
                    const updateCallback = () => {
                      const xUpdate = [];
                      const yUpdate = [];
                      for (let j = 0; j < 5; i++, j++) {
                        xUpdate.push(i);
                        yUpdate.push(0.2 * Math.sin(i * 0.1) - Math.cos(i * 0.01));
                      }
                      // With fifoCapacity set, just append new points.
                      xyDataSeries.appendRange(xUpdate, yUpdate);
                      // result: dataSeries length remains the same. point counts > fifoCapacity are discarded.
                      // as x-value increases, and xAxis.autoRange zooms to fit, the chart scrolls
                    };

                    setTimeout(() => {
                      updateCallback();
                      setInterval(updateCallback, 20);
                    }, 20);
                    return { sciChartSurface };
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <span className="corner-bottom-left"></span>
          <span className="corner-bottom-right"></span>
        </div>
      </Modal>
    </div>

  );
}

export default MainDash;
