import { useState, useEffect } from "react";
import React from 'react'
import { Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { fa } from "@fortawesome/free-solid-svg-icons";
import "./LeftColoumn.css"

export default function LeftColoumn() {
  const [isOn, setIsOn] = useState(true);
  const [isFirstAlarmOn, setIsFirstAlarmOn] = useState(true);

  const toggleButton = () => {
    setIsOn((prevState) => !prevState);
  };

  useEffect(() => {
    // Set up an interval to toggle the alarms every 1 second
    const interval = setInterval(() => {
      setIsFirstAlarmOn((prevState) => !prevState);
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='container-fluid'>
      <Row className='ml-2'>
        <Col md={12}>
        <h5>NODE STATUS</h5>
          <div style={{ display: "flex", alignItems: "center" }}>
            <button onClick={toggleButton}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                backgroundColor: isOn ? "green" : "red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                border: "none",
                fontSize: "18px",
              }}
            >
              {isOn ? "ON" : "OFF"}
            </button>
          </div>
        </Col>

        <Col className="mt-5 text-white" md={12}>
          <h5>ALARM</h5>
          <div>
            {/* First Icon and Label */}
            <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
              {/* Icon */}
              <div style={{ width: "40px", height: "40px", marginRight: "10px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                  width="40px"
                  height="40px"
                >
                  <rect x="25" y="60" width="50" height="15" rx="2" ry="2" fill="#4A4A4A" />
                  <path
                    d="M25,60 A25,25 0 0,1 75,60 L75,60 Z"
                    fill={isFirstAlarmOn ? "gray" : "red"}
                  />
                </svg>
              </div>
              {/* Label */}
              <div>Needs Maintenance</div>
            </div>

            {/* Second Icon and Label */}
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* Icon */}
              <div style={{ width: "40px", height: "40px", marginRight: "10px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                  width="40px"
                  height="40px"
                >
                  <rect x="25" y="60" width="50" height="15" rx="2" ry="2" fill="#4A4A4A" />
                  <path
                    d="M25,60 A25,25 0 0,1 75,60 L75,60 Z"
                    fill={isFirstAlarmOn ? "red" : "gray"}
                  />
                </svg>
              </div>
              {/* Label */}
              <div>Downtime</div>
            </div>
          </div>
        </Col>
        <Col className="mt-5" md={12}>
          <h5>MATERIAL</h5>
        </Col>
        <Col className="mt-5" md={12}>
        <h5>TESTING</h5>
        </Col>
        <Col className="mt-5 text-white" md={12}>
          <h5>MAINTENANCE</h5>
          <div className="mb-2" style={{ width: "100%", maxWidth: "400px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
              <span>Maintenance</span>
              <span>8,117 h</span>
            </div>
            <div style={{ background: "#555", borderRadius: "5px", overflow: "hidden", position: "relative", height: "30px" }}>
              <div style={{ width: "80%", background: "#344675", height: "100%" }}></div>
            </div>
            <div style={{ fontSize: "12px", marginTop: "5px", color: "#aaa" }}>
              Last Maintenance Date: 4/12/2023 3:04:56 PM
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}


