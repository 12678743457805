import { useSimulation } from '../SimulationContext'


import './ScenePreviewHeader.scss'
import { API_ASSET_URL } from '../common/config'

export default function ScenePreviewHeader({ isFullscreen, setIsFullscreen }) {
  const { isSessionStarted, selectedSession } = useSimulation()

  return (
    <div className="scene-preview-header-container">
      {isFullscreen && <label className="subtask-label">{selectedSession?.name}</label>}
      <label className={isSessionStarted ? 'centered-change-view-label' : ''}>Change View</label>
      {isSessionStarted && (
        <div className={isFullscreen ? 'icon-container' : ''}>
          <img src={isFullscreen ? `${API_ASSET_URL}/assets/images/shrink.png` : `${API_ASSET_URL}//assets/images/stretch.png`} onClick={() => setIsFullscreen(!isFullscreen)} />
        </div>
      )}
    </div>
  )
}
