import React from 'react'

export default function NewEarth() {
  return (
    <div>
        <iframe
            src="https://react-three-gps.vercel.app/"
            title="Earth"
            style={{
              width: "100%",
              height: "100vh",
              border: "none",
            }}
          />
    </div>
  )
}
