import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
//import "./HeatmapChart.css"; // Import CSS for styling

const HeatmapChart = ({ isModalOpen, chartData }) => {
  console.log("heatmapm",chartData)
  const [data, setData] = useState(
    Array.from({ length: 10 }, () =>
      Array.from({ length: 10 }, () => Math.random() * 100)
    ).flatMap((row, i) =>
      row.map((value, j) => [i, j, Math.floor(value)])
    )
  );

  const option = {
    title: {
      // text: `${chartData?.metric ? chartData.metric : 'Thermal Conductivity' }`,
      left: "center",
      textStyle: {
        fontSize: 12,
        fontWeight: "",
        color: "#FFF",
        fontFamily: "Alternox-Light",
      },
    },
    tooltip: {
      position: "top",
      backgroundColor: "#333",
      borderColor: "#00E5FF",
      textStyle: {
        color: "#FFF",
      },
    },
    xAxis: {
      type: "category",
      data: Array.from({ length: 10 }, (_, i) => i),
      axisLine: { lineStyle: { color: "#FFF" } },
      axisLabel: { color: "#AAA" },
    },
    yAxis: {
      type: "category",
      data: Array.from({ length: 10 }, (_, i) => i),
      axisLine: { lineStyle: { color: "#FFF" } },
      axisLabel: { color: "#AAA" },
    },
    visualMap: {
      min: 0,
      max: 100,
      calculable: true,
      orient: "horizontal",
      left: "center",
      bottom: "5%",
      inRange: {
        color: ["#FF0000", "#FF7F00", "#FFFF00"], // Gradient for values
      },
      textStyle: {
        color: "#FFF",
      },
    },
    series: [
      {
        name: "Heatmap",
        type: `${chartData?.chartData ? chartData.graphType : 'heatmap'}`,
        data: data,
        label: {
          show: true,
          color: "#FFF", // Value labels in white
          fontSize: 10,
        },
        emphasis: {
          itemStyle: {
            borderColor: "#FFF",
            borderWidth: 1,
          },
        },
      },
    ],
    backgroundColor: "" // Dark background
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setData(
        Array.from({ length: 10 }, () =>
          Array.from({ length: 10 }, () => Math.random() * 100)
        ).flatMap((row, i) =>
          row.map((value, j) => [i, j, Math.floor(value)])
        )
      );
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  // Dynamic height adjustment
  const chartHeight = isModalOpen ? "500px" : "250px";

  return (
    <ReactECharts option={option} style={{ height: chartHeight, width: "100%" }} />
  );
};

export default HeatmapChart;
